import React, {useState, useEffect} from "react";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import AddShoppingCart from "@material-ui/icons/AddShoppingCart";
import Add from "@material-ui/icons/Add";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {dataSourceV2} from "commons-ui/core/DataSource";
import Loading from "commons-ui/core/Loading";
import CRUD from "commons-ui/core/CRUD";
import CountWidget from "./CountWidget";
import {calcPrice, renderFeatureVertical} from "ecommerce-commons-ui/core/utils";
import {
    onAddFilterTemplate,
    onDeleteFilterManyTemplate,
    onDeleteFilterTemplate,
    onLocationChangeTemplate,
    onResetFacetFiltersTemplate,
    requestProductListTemplate
} from "ecommerce-commons-ui/core/ProductGallery";
import "./style.styl";


export default function AddNewWidget(props) {
    const {open, locationId, onAdd, isFullscreen, onClose, ecommerceStoreGroupId, ecommerceStoreId, onSelect, isMobile, mediaPath, apiPath} = props;
    const [loading, setLoading] = useState(true);
    const [itemData, setItemData] = useState({});
    const [categoryList, setCategoryList] = useState([]);
    const [productDictByCategory, setProductDictByCategory] = useState({});
    const {itemList, pageTotal, itemTotal, pageSize, searchSynonymList} = itemData;
    const [countDict, setCountDict] = useState({});
    const onCount = (id, value) => {
        const newD = {...countDict};
        newD[id] = value;
        setCountDict(newD);
    };


    //const request = requestProductListTemplate({setItemData, path: "ecommerce_product_view_list", itemId: "ecommerce_product_id", postprocess: "default", apiPath, ecommerceStoreGroupId, ecommerceStoreId, loading, setLoading, searchInStoreGroup: true, noFacet: true, hiddenDirect: true, pageSize: 100});

    const requestProductList = async (category) => {
      const query = {
        size: 100,
        sort: [],
        _source: ["search_result_data"],
        query: {
          bool: {
            must: [],
            filter: [
              { term: { "search_data.ecommerce_store_id": ecommerceStoreId } },
            ],
            //filter: [{ term: { "category.id_list": category } }],
            must_not: [{ term: { "hidden.id_list": "default" } }, { term: { "inventory_hidden.id_list": locationId } }],
          },
        },
      };

      return dataSourceV2(
        `ecommerce_product_view_list?is_search=true&postprocess=${locationId}&query=${JSON.stringify(
          query
        )}`,
        { url: apiPath }
      ).then((v) => {
        if (!v.message && v.item_list) {
          const itemList = v.item_list.map((data) => {
            const category_id_dict = data.category_id_dict || {};
            const version = data.version || {};

            return {
              ...version,
              category_id_dict,
              attribute_set_id: data.attribute_set_id,
              ecommerce_product_id: data.ecommerce_product_id,
            };
          });
          return itemList;
        }
        return [];
      });
    };

    const requestCategoryList = async () => {
        const categoryListResponse = await dataSourceV2(
          `ecommerce_product_category_list?ecommerce_store_id=${ecommerceStoreId}`,
          { url: apiPath }
        );

        const categoryList = (categoryListResponse.item_list || []).filter(
          (category) => {
            return (
              !category.is_hidden &&
              (!category.type ||
                category.type === "primary" ||
                category.type === "preview")
            );
          }
        );

        const productList = await requestProductList();
        const productDictByCategory = {};

        for (let i = 0; i < categoryList.length; i++) {
          const categoryId = categoryList[i].ecommerce_product_category_id;

          const productListFiltered = productList.filter(v => {
              return !!(v.category_id_dict || {})[categoryId];
          });
          productDictByCategory[categoryId] = productListFiltered;
        }
        setCategoryList(categoryList);
        setProductDictByCategory(productDictByCategory);
        setLoading(false);
        //console.log(123, categoryList, productDictByCategory);
    };

    useEffect(() => {
        if (locationId) requestCategoryList();
    }, [locationId]);


    const itemListRender = (itemList) => {
        return (
            <div>
                {(itemList || []).map((product, i) => {
                    const {price, price_fixed, discount_fixed, discount_percent} = calcPrice(product.product || product, product.featureList);
                    const path = product.images ? mediaPath + (product.images || "").split(",")[0] : "";
                    const id = product.ecommerce_product_id || "";
                    return (
                        <ListItem dividers key={i} className="u-paddingVertical0 paddingHorizontal24 u-borderBottom1 u-borderColorLightest">
                            <div className="u-flexCenter u-sizeFullWidth u-paddingVertical4">
                                <div className="u-relative u-inlineBlock u-marginVertical4 u-verticalAlignTop u-marginRight10">
                                    <div className={"image-widget-item u-relative u-width80 u-height80 u-borderRadius8"}
                                         style={{backgroundImage: `url(${path})`, backgroundSize: "contain"}}></div>
                                </div>
                                <div className="u-sizeFullWidth u-flexColumn">
                                    <div className="u-fontSize13 u-textColorNormal u-marginBottom10">{product.name}</div>
                                    <div className="u-fontSize15 u-flex u-alignItemsSpaceBetween u-justifyContentSpaceBetween">
                                        <div className="u-flexCenter">
                                            <span className="u-marginRight10">${((Math.round(price * 100) / 100) || 0)}</span>
                                            {/*<CountWidget itemIdValue={id} value={countDict[id] || 1} onChange={(value) => onCount(id, value)}/>*/}
                                        </div>
                                        <div className="u-flexCenter">
                                            <Button
                                              className="button-secondary"
                                              size="small"
                                              onClick={(event) => {
                                                  onAdd(product, id);
                                                  //dispatch("cart", { product: item, count: 1, ecommerceStoreId }, "add_product");
                                              }}
                                            >
                                              Add{" "}
                                              <Add fontSize="small" className="u-marginLeft5" />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ListItem>
                    );
                })}
            </div>
        );
    };

    const categoryListRender = () => {
        return (
            <List className="cart-content u-paddingTop0 ">
                {
                    loading ?
                        <Loading/>
                        :
                        null
                }
                {
                    categoryList.map((category, i) => {
                      const id = category.ecommerce_product_category_id;
                      const name = category.name;

                      const productList = productDictByCategory[category.ecommerce_product_category_id] || [];
                      if (productList.length === 0) {
                          return null;
                      }
                      return (
                          <div key={id}>
                              <div
                                id={id}
                                className="u-sizeFullWidth u-textColorPrimary u-paddingHorizontal20 u-paddingVertical5 u-textAlignCenter u-paddingBottom15 u-borderBottom1 u-borderColorLightest"
                                style={{ fontFamily: "Piroshky", letterSpacing: "2px", fontSize: "20px" }}
                              >
                                {name}
                              </div>
                              <div className="u-marginBottom20">
                                  {itemListRender(productList)}
                              </div>
                          </div>
                      );
                    })
                }
            </List>
        );
    };

    if (isMobile) {
        return (
            <SwipeableDrawer
              open={open}
              onOpen={() => null}
              className="swipable-menu"
              onClose={onClose}
            >
                <div>
                    <div className="u-flexCenter u-justifyContentSpaceBetween u-height65 u-hide">
                        <div className="u-flexCenter">
                            <DialogTitle>Menu</DialogTitle>
                        </div>
                        <div>
                            <Tooltip title="Close">
                                <IconButton className="u-marginRight10"
                                            onClick={onClose}>
                                    <Close/>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <span className="u-flex u-paddingTop20">
                        {categoryListRender()}
                    </span>
                </div>
            </SwipeableDrawer>
        );
    }

    return (
        <Dialog
            fullScreen={isFullscreen}
            maxWidth="xs"
            fullWidth
            className="tree-widget"
            open={open}
            onClose={onClose}

            >
            <div className="u-flexCenter u-justifyContentSpaceBetween u-height65">
                <div className="u-flexCenter">
                    <DialogTitle>Menu</DialogTitle>
                </div>
                <div>
                    <Tooltip title="Close">
                        <IconButton className="u-marginRight10"
                                    onClick={onClose}>
                            <Close/>
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            <DialogContent className="">
                {categoryListRender()}
            </DialogContent>
        </Dialog>
    );
}
