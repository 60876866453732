import React, {useState, useEffect, useMemo} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import {Form} from "formik";
import {dataSourceV2} from "commons-ui/core/DataSource";
import {dateToTime, dateToTimeAMPM} from "commons-ui/core/utils/date";
import {prettyDateUSA, addZeroToDate, timeStringToDate, dateToISODate} from "commons-ui/core/utils/date";
import Loading from "commons-ui/core/Loading";
import renderHTML from "react-render-html";
import {KeyboardDatePicker, TimePicker} from "@material-ui/pickers";
import Calendar from "react-calendar";
import Tooltip from "@material-ui/core/Tooltip";
import { calcDeliveryCost } from "../index";
import HelpOutline from "@material-ui/icons/HelpOutline";
import {getFromLS} from "commons-ui/core/utils/index";
import "./style.styl";
import "react-calendar/dist/Calendar.css";




export default function EventForm(props) {
    const {values, description, itemId, modelName, itemIdParent, readOnly, readOnlySelect, itemIdValueParent, errors, touched, onBack, setFieldValue, isSubmitting, apiPath} = props;
    const [items, setItems] = useState([]);
    const [dateDict, setDateDict] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const dateSelected = values.event_date_selected;


    const request = () => {
        setLoading(true);
        const date = `${dateSelected.getFullYear()}-${addZeroToDate(dateSelected.getMonth() + 1)}-${addZeroToDate(dateSelected.getDate())}`;
        dataSourceV2(`${modelName}_list?is_future=true&${itemIdParent}=${itemIdValueParent}`, {url: apiPath})
            .then(v=> {
                if (!v.message && v.item_list) {
                    const now = new Date();
                    const dateDict = {};
                    const items = v.item_list.filter((v, i) => {
                        if (v.is_sold_out || v.is_postponed) {
                            return false;
                        }
                        if (v.cutoff_at) {
                            const cutoffAt = new Date(v.cutoff_at);
                            if (now > cutoffAt) {
                                return false;
                            }
                        }
                        dateDict[dateToISODate(new Date(v.start_at))] = true;
                        dateDict[dateToISODate(new Date(v.end_at))] = true;
                        return true;
                    });
                    setDateDict(dateDict);
                    items.sort((a,b) => (a.start_at > b.start_at) ? 1 : ((b.start_at > a.start_at) ? -1 : 0));
                    setItems(items);

                }
                setLoading(false);
            });
    };


    const requestDateDict = () => {
        dataSourceV2(`ecommerce_store_delivery_option_event_calendar?${itemIdParent}=${itemIdValueParent}`, {url: apiPath})
            .then(v=> {
                if (!v.message && v.item_dict) {
                    setDateDict(v.item_dict);
                }
            });
    };

    const item = useMemo(() => {
        const itemList = items.filter(v => v[itemId] === values[itemId]);
        if (itemList.length) return itemList[0];
        return {};
    }, [values[itemId], items.length]);

    useEffect(() => {
        if (itemIdValueParent) request();
    }, [itemIdValueParent, dateSelected]);

    useEffect(() => {
        //if (itemIdValueParent) requestDateDict()
    }, [itemIdValueParent]);

    useEffect(() => {
        return
        setFieldValue("min_order", item.min_order || props.item.min_order || 0);
    }, [props.item.min_order, item.min_order]);

    useEffect(() => {
        return
        const cost = calcDeliveryCost(values.base_amount, item.delivery_cost_json, item.cost || props.item.cost || 0)
        setFieldValue("delivery_amount", cost);
    }, [item.cost, props.item.cost, item.delivery_cost_json, values.base_amount]);

    useEffect(() => {
        return
        setFieldValue("packaging_amount", item.packaging_cost || props.item.packaging_cost || 0);
    }, [item.packaging_cost, props.item.packaging_cost]);

    useEffect(() => {
        return
        setFieldValue("tax_rate_global", item.tax_rate_percent || 0);
    }, [item.tax_rate_percent]);

    useEffect(() => {
        const eventDate = new Date(item.start_at);
        if (eventDate) {
            setFieldValue("event_date_selected", eventDate);
        }
    }, [item.start_at]);

    useEffect(() => {
        return
        if (item.has_labeling_cost && item.labeling_cost) setFieldValue("labeling_cost", item.labeling_cost || 0);
    }, [item.has_labeling_cost, item.labeling_cost]);

    useEffect(() => {
        setFieldValue("ecommerce_store_delivery_option_event", item);
    }, [Object.keys(item).length]);

    const cutoffAt = item.cutoff_at ? new Date(item.cutoff_at) : undefined;


    let isNoEventsOnSelectedDate = true;
    let comingSoonIndex = undefined;

    items.forEach((v, i) => {
        if (!v.start_at || !v.end_at) return null;
        const startAt = new Date(v.start_at);
        startAt.setHours(0, 1);
        const endAt = new Date(v.end_at);
        endAt.setHours(23, 59);

        if (dateSelected.getTime() > endAt.getTime()) return null;

        const isComingSoon = dateSelected.getTime() < startAt.getTime();

        if (isComingSoon) {
            if (comingSoonIndex === undefined) {
                comingSoonIndex = i;
            }
        } else {
            isNoEventsOnSelectedDate = false;
        }
    });

    return (
            <React.Fragment>
                <div className="event-delivery-form u-flex u-justifyContentSpaceBetween u-xs-flexColumn">
                    <div className="u-sizeFullWidth u-maxWidth400">
                        {
                            !readOnly &&
                                <div className="u-marginTop12 u-marginBottom20">
                                    {
                                        dateDict ?
                                            <Calendar
                                                onChange={(d) => {
                                                    if (d) {
                                                        d.setHours(12);
                                                    }
                                                    setFieldValue("event_date_selected", d);
                                                    setFieldValue(itemId, "");
                                                }}
                                                value={dateSelected}
                                                next2Label={null}
                                                prev2Label={null}
                                                calendarType={"US"}
                                                locale={"en-US"}
                                                tileDisabled={({activeStartDate, date, view }) => {
                                                    return !dateDict[dateToISODate(date)];
                                                }}
                                                minDate={new Date()}
                                              />
                                          :
                                          null
                                    }
                                </div>
                        }

                        {
                            loading ?
                                <Loading className="u-marginTop20 u-marginBottom20"/>
                                :
                                (
                                    items.length ?
                                        <List className="">
                                            {
                                                items.map((v, i) => {

                                                    if (!v.start_at || !v.end_at) return null;
                                                    if (readOnly && v[itemId] !== values[itemId]) {
                                                        return null;
                                                    }
                                                    const selected = v[itemId] === values[itemId];
                                                    const startAt = new Date(v.start_at);
                                                    startAt.setHours(0, 1);
                                                    const endAt = new Date(v.end_at);
                                                    endAt.setHours(23, 59);

                                                    if (dateSelected.getTime() > endAt.getTime()) return null;
                                                    //if (dateSelected.getTime() < startAt.getTime()) return null


                                                    let date = prettyDateUSA(startAt);
                                                    if (startAt.getTime() !== endAt.getTime()) {
                                                        date += " - " + prettyDateUSA(endAt);
                                                    }

                                                    const cutoffAt = v.cutoff_at ? new Date(v.cutoff_at) : undefined;

                                                    const isComingSoon = comingSoonIndex === i;

                                                    if (i - comingSoonIndex >= 3) return null;

                                                    return (
                                                      <div key={i}>
                                                        {
                                                            isComingSoon && !readOnly ?
                                                                <span className="u-flexCenter u-justifyContentCenter u-fontWeightBold u-marginBottom10 u-textColorNormal">
                                                                    Events After {prettyDateUSA(dateSelected)}
                                                                </span>
                                                                :
                                                                null
                                                        }
                                                        <ListItem   selected={selected} button={!readOnly && !readOnlySelect} key={i} className={"u-textColorNormal u-fontSize12 " + (i >= comingSoonIndex && !selected ? " u-opacity50" : "")}
                                                                    onClick={() => {(readOnly || readOnlySelect) ? null : setFieldValue(itemId, selected ? "" : v[itemId]); setFieldValue("event_date_selected", new Date(v.start_at)); setFieldValue("delivery_requested_time_exact", "12:00:00"); }}>
                                                                <div>
                                                                    <div className="u-fontWeightBold">
                                                                        {v.name}
                                                                    </div>
                                                                    <div className=" u-fontSize9 u-marginTop2">{v.address}{v.city ? ", " + v.city : ""}{v.state ? ", " + v.state : ""}{v.postal_code ? ", " + v.postal_code : ""}</div>
                                                                    <div>
                                                                        {date ? <span className=" u-fontSize9 u-marginTop2">{date}</span> : ""}
                                                                    </div>
                                                                </div>
                                                        </ListItem>
                                                      </div>

                                                    );
                                                })
                                            }
                                        </List>
                                        :
                                        <div className="u-marginTop15 u-textColorNormal">No Events. Select another date</div>
                                )
                        }

                        <TextField
                            label="Comments (Special Instructions)"
                            className="u-sizeFullWidth u-marginVertical12 u-hide"
                            margin="dense"
                            multiline
                            rows={2}
                            disabled={readOnly}
                            error={errors.customer_note && touched.customer_note}
                            helperText={errors.customer_note && touched.customer_note ? errors.customer_note : ""}
                            onChange={(e) => setFieldValue("customer_note", e.target.value)}
                            value={values.customer_note}
                            variant="outlined"
                        />


                    {/*{
                          !loading && values[itemId] && item.order_window_start_at && item.order_window_end_at ?
                              <TimePicker
                                  showTodayButton
                                  todayLabel="now"
                                  label={`Pick Up Time Between ${dateToTimeAMPM(timeStringToDate(item.order_window_start_at))} and ${dateToTimeAMPM(timeStringToDate(item.order_window_end_at))}`}
                                  color="secondary"
                                  disabled={readOnly}
                                  value={values.delivery_requested_time_exact ? timeStringToDate(values.delivery_requested_time_exact) : null}
                                  //error={errors.delivery_requested_time_exact && touched.delivery_requested_time_exact}
                                  helperText={(errors.delivery_requested_time_exact && touched.delivery_requested_time_exact) ? errors.delivery_requested_time_exact : undefined}
                                  className="u-marginVertical12 u-sizeFullWidth u-marginRight10 u-xs-marginRight0"
                                  inputVariant="outlined"
                                  margin="dense"
                                  minutesStep={5}
                                  onChange={(date) => {
                                      if (!date || isNaN(date.getTime())) {

                                      } else {
                                          let newDate = date;
                                          if (item.order_window_start_at && item.order_window_end_at) {
                                              const now = new Date();
                                              const min = timeStringToDate(item.order_window_start_at);
                                              console.log(min, item.order_window_start_at);
                                              const max = timeStringToDate(item.order_window_end_at);
                                              min.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());
                                              max.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());
                                              date.setFullYear(now.getFullYear(), now.getMonth(), now.getDate());

                                              if (min.getTime() > date.getTime()) {
                                                  newDate = min;
                                              }
                                              if (max.getTime() < date.getTime()) {
                                                  newDate = max;
                                              }
                                              //console.log(min.toISOString(), max.toISOString(), date.toISOString())
                                          }

                                          const d = newDate.toISOString().split("T")[1].split(".")[0];
                                          setFieldValue("delivery_requested_time_exact", d);
                                      }
                                  }}
                                />
                            :
                            null
                      }*/}


                        {
                            !loading && errors[itemId] && touched[itemId] ?
                                <div className="MuiFormHelperText-root Mui-error u-marginBottom15 u-sizeFullWidth u-textColorRed">
                                    {errors[itemId]}
                                </div>
                                :
                                null
                        }



                        {
                            !loading && (item.cost || props.item.cost) ?
                                <div className="u-marginTop8 u-fontSize10 u-sizeFullWidth">
                                    Pick Up Cost: {"$" + (parseFloat(item.cost) || parseFloat(props.item.cost) || 0).toFixed(2)}
                                </div>
                                :
                                null
                        }


                        {
                            !loading && (item.packaging_cost || props.item.packaging_cost) ?
                                <div className="u-marginTop8 u-fontSize10  u-sizeFullWidth">
                                    Packaging Cost: {"$" + (parseFloat(item.packaging_cost) || parseFloat(props.item.packaging_cost) || 0).toFixed(2)}
                                </div>
                                :
                                null
                        }


                        {
                            !loading && (item.min_order || props.item.min_order) ?
                                <div className="u-flexColumn u-marginTop8 u-fontSize10 u-sizeFullWidth">
                                    <span className="u-flexCenter">
                                        Min. Order: {"$" + (parseFloat(item.min_order) || parseFloat(props.item.min_order) || 0).toFixed(2)}
                                        <Tooltip title={"Min. order of in-house items before tax"}>
                                            <HelpOutline fontSize="small" className="u-textColorNormal u-marginLeft5"/>
                                        </Tooltip>
                                    </span>

                                    <span>{errors.min_order && touched.min_order ? <div className="MuiFormHelperText-root Mui-error">{errors.min_order}</div> : ""}</span>
                                </div>
                                :
                                null
                        }

                        {
                            !loading && (item.max_order) ?
                                <div className="u-marginTop8 u-fontSize10">
                                    Max. Order: {"$" + (parseFloat(item.max_order) || parseFloat(props.item.max_order) || 0).toFixed(2)}
                                </div>
                                :
                                null
                        }

                        {
                            cutoffAt ?
                                <div className="u-marginTop8 u-fontSize10">
                                    <span className="">Cutoff At: <span className="">{prettyDateUSA(cutoffAt)} {dateToTimeAMPM(cutoffAt)}</span></span>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className="u-sizeFullWidth u-maxWidth400">
                        <div className="u-textColorNormal u-marginTop8 u-xs-marginTop25 u-fontSize13">
                            {(item.description || description) && renderHTML(item.description || description)}
                        </div>
                    </div>
                </div>




            </React.Fragment>
    );
}
