import React, { useEffect, useState } from "react";
import { Logo } from "../../Logo"
import "./SuccessPage.module.scss";


export const SuccessPage = (props) => {
    const { show, isMobile, onClose, dispatch } = props;
    const [animated, setAnimated] = useState(false);
    const [animatedInitial, setAnimatedInitial] = useState(false);

    useEffect(() => {
        if (!show) {
            return;
        }
        const t1 = setTimeout(() => setAnimatedInitial(true), 50);
        const t2 = setTimeout(() => setAnimated(true), 300);
        return () => {
            clearTimeout(t1);
            clearTimeout(t2);
        };
    }, [show]);

    if (!show) {
        return null
    }

    return (
        <div id="lightbox-validation" className={"" + (show ? " show" : "")}>
            <div className={`content ${animated ? "show" : ""}`}>

                <div className="u-flexCenter u-justifyContentCenter">
                    <Logo size="large" />
                </div>
                <div className="container-text-box">
                    <div className="line"><span>THANK YOU {isMobile ? <br /> : ""}FOR YOUR ORDER!</span></div>
                    <div className="line sub u-opacity60 u-marginTop5"><span>CHECK YOUR EMAIL</span></div>
                    <div className="line sub u-opacity60"><span>FOR CONFIRMATION</span></div>
                    <div className="line u-opacity60 u-fontSize10">
                        <span>
                            Didn’t receive your confirmation? Check your spam folder.
                        </span>
                    </div>
                </div>
                <div className="u-xs-flexColumn u-marginTop50">
                    <div className="btn u-cursorPointer u-margin10"
                        onClick={() => onClose()}
                        link-meta="/">
                        <p className="anim-btnHover-1">
                            HOME
                        </p>
                    </div>

                </div>
            </div>

        </div>
    );
}
