import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CountWidget from "./CountWidget";
import AddNewWidget from "./AddNewWidget";
import UpsellingV2 from "ecommerce-commons-ui/core/UpsellingV2";
import {calcPrice, renderFeatureVertical} from "../../../../utils";
import "./style.styl";


export default function CartContent(props){
    const {items, values, cart, type, deliveryType, isMobile, setFieldValue, isOpen, onClose, ecommerceStoreId, onNextStep, drawerClassName, mediaPath, noImage, noWatermark, dispatch} = props;
    const [isAddNewOpen, setIsAddNewOpen] = useState(false);
    const total = items.reduce((a, b) => a + (calcPrice(b.product || {}, b.featureList).final_price || 0) * (b.count || 0), 0).toFixed(2);

    const deliveryItem = (cart || {}).deliveryItem || {};
    const locationId = deliveryItem.ecommerce_store_delivery_option_event_id || deliveryItem.ecommerce_store_delivery_option_pickup_id || deliveryItem.ecommerce_store_delivery_option_rule_id;


    const onAdd = (item) => {
        dispatch("cart", { product: item, count: 1, ecommerceStoreId, deliveryItem: cart.deliveryItem}, "add_product_without_cart");
    };

    let categoryIdDict = {};
    cart.items.forEach(item => {
        const product = item.product || {};
        categoryIdDict = {...categoryIdDict, ...(product.category_id_dict || {})};
    })
    const categoryIds = Object.keys(categoryIdDict);

    const labelingAmount = values.labeling_cost || 0;


    return (
        <div className="u-flexColumn u-sizeFullWidth">
            <div className="u-flexColumn  u-sizeFullWidth u-maxWidth600">
                <div className="u-flexColumn u-backgroundColorWhite u-border1 u-borderColorLighter u-padding15 u-borderRadius10 u-sizeFullWidth u-maxWidth600">
                    <List className="cart-content u-paddingTop0">
                        {
                            items.map((item, i) => {
                                const featureDict = item.featureDict || {};
                                const product = item.product || {};
                                const {price, price_fixed, discount_fixed, discount_percent} = calcPrice(product, item.featureList);
                                const path = product.images ? mediaPath + (product.images || "").split(",")[0] : (noImage || "https://assets.smallcase.com/images/smallcases/200/SCSB_0004.png");
                                const cartLimit = parseInt(product.cart_limit || "0") || 0;
                                return (
                                    <ListItem key={i} className="u-padding0">
                                        <div className="u-flexCenter u-sizeFullWidth u-paddingVertical10">
                                            <div className={isMobile ? "u-sizeFullWidth u-flexColumn" : "u-sizeFullWidth u-flexCenter u-justifyContentSpaceBetween"}>
                                                <div className="u-flexCenter u-fontSize13 u-textColorDarker u-xs-marginBottom5">
                                                    <span className="u-marginRight10">{product.name}</span>
                                                </div>
                                                {
                                                    deliveryType === "shipping_event" ?
                                                        <div className={"u-fontSize15 u-textColorDarker u-flexCenter "}>
                                                            <div    className={"u-flexCenter u-fontSize12"}>
                                                                    qty: {item.count || 0}
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className={"u-fontSize15 u-textColorDarker u-flexCenter u-marginLeft10 u-xs-margin0 u-xs-sizeFullWidth u-justifyContentSpaceBetween "}>
                                                            <div className="u-flexCenter">
                                                                <div    className={"u-flexCenter u-fontWeightBold u-fontSize15"}>
                                                                    $ {discount_percent ? discount_fixed : price_fixed}
                                                                </div>
                                                                {
                                                                    discount_percent ?
                                                                        <div className="u-flexCenter u-marginLeft10">
                                                                            <div    className={"u-flexCenter u-fontSize9"}
                                                                                    style={{color: "#9d9ca5", textDecoration: "line-through"}}>
                                                                                $ {price_fixed}
                                                                            </div>
                                                                            <div className="discount_percent u-marginLeft5">
                                                                                {discount_percent}%
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className="u-flexCenter u-marginLeft20">
                                                                {
                                                                    product.slug === "piroshky_celebration_pack" || product.slug === "piroshky_summer_pack" ?
                                                                        null
                                                                        :
                                                                        <CountWidget itemIdValue={item.id} value={item.count || 0} dispatch={dispatch} limit={cartLimit}/>
                                                                }
                                                                
                                                                <div className="u-flexCenter u-marginLeft5">
                                                                    <IconButton className="u-padding2 u-opacity80" onClick={() => dispatch("cart", {id: item.id}, "remove_product")}>
                                                                        <Delete className="u-fontSize15"/>
                                                                    </IconButton>
                                                                </div>
                                                            </div>
                                                        </div>
                                                }
                                                
                                            </div>
                                        </div>
                                    </ListItem>
                                );
                            })
                        }
                    </List>
                    <div className="cart-actions">
                        <div className="u-paddingTop15 u-flexCenter u-justifyContentSpaceBetween u-borderTop1 u-borderColorLightest">
                            <div className="">
                                <div className="u-fontSize15">Total: <span className="u-fontWeightBold">$ {total}</span></div>
                            </div>

                            <div className={"u-flexCenter " + (deliveryType === "shipping_event" ? "u-hide" : "")}>
                                <Button size="small" className="button-secondary" variant="outlined" onClick={() => setIsAddNewOpen(true)}>
                                    <Add fontSize="small" className="u-marginRight5"/>Add More
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    labelingAmount ?
                        <div className="u-marginTop20 u-flexColumn u-backgroundColorWhite u-border1 u-borderColorLighter u-padding15 u-borderRadius10 u-sizeFullWidth u-maxWidth600">
                            <span className="u-flexCenter u-justifyContentSpaceBetween">
                                <span>Item Labeling: +${labelingAmount.toFixed(2)}</span>

                                <span>
                                    {
                                        values.labeling_amount ?
                                            <Button className="button-grey u-marginLeft10" size="small" onClick={() => setFieldValue("labeling_amount", 0)}>Remove</Button>
                                            :
                                            <Button className="button-secondary u-marginLeft10" size="small" onClick={() => setFieldValue("labeling_amount", labelingAmount)}>Add</Button>
                                    }
                                </span>
                            </span>
                        </div>
                        :
                        null
                }
                {
                    deliveryType === "pick_up" && (deliveryItem.name || "").toLowerCase().includes("kent") ?
                        <div className="u-marginTop20 u-flexColumn u-backgroundColorWhite u-border1 u-borderColorLighter u-padding15 u-borderRadius10 u-sizeFullWidth u-maxWidth600">
                            <span className="u-flexCenter u-justifyContentSpaceBetween">
                                {
                                    values.is_frozen ?
                                        <span>Your order will be <b style={{color: "#6565ec"}}>FROZEN</b></span>
                                        :
                                        <span>Make it frozen: +$0.00</span>
                                }

                                <span>
                                    {
                                        values.is_frozen ?
                                            <Button className="button-grey u-marginLeft10" size="small" onClick={() => setFieldValue("is_frozen", false)}>Undo</Button>
                                            :
                                            <Button className="button-secondary u-marginLeft10" size="small" onClick={() => setFieldValue("is_frozen", true)}>Apply</Button>
                                    }
                                </span>
                            </span>
                        </div>
                        :
                        null
                }

                {
                    isAddNewOpen ?
                        <AddNewWidget {...props} open={isAddNewOpen} locationId={locationId} isFullscreen={isMobile} onAdd={onAdd} onClose={() => setIsAddNewOpen(false)}/>
                        :
                        null
                }
            </div>
            <div className="u-marginTop20 u-sizeFullWidth">
                {
                    locationId && ecommerceStoreId && deliveryType !== "shipping_event" ?
                        <UpsellingV2    {...props}
                                        columns={5}
                                        size={5}
                                        isMobile={isMobile}
                                        isSmall
                                        categoryIds={categoryIds}
                                        ecommerceStoreId={ecommerceStoreId}
                                        locationId={locationId}
                                        mobileWidth={155}
                                        onItemClick={() => null}
                                        onAdd={(item) => onAdd(item)}
                                        exclude={items.map(v => v.id)}

                        />
                        :
                        null
                }

            </div>
        </div>
    );
}
