import React, { useEffect } from "react";

import LoginForm from "./LoginForm";
import ConfirmForm from "./ConfirmForm";

import { useStore } from "../../../context/state";

import Loading from "commons-ui/core/Loading";

export const Page = ({ type, loading, options, onCheck, onSignUp, onSuccess }) => {
  const { dispatch, apiPath } = useStore();

  useEffect(() => {
    onCheck();
  }, []);

  if (loading) {
    return (
      <div className="">
        <div
          className="u-marginTop50 u-padding25 u-flexCenter u-justifyContentCenter"
        >
          <Loading color="#50019f" />
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <div
        className=""
      >
        {loading ? (
          <Loading color="#50019f" />
        ) : type === "confirmation" ? (
          <ConfirmForm
            options={options}
            onCheck={onCheck}
            apiPath={apiPath}
            dispatch={dispatch}
            onSignUp={onSignUp}
            onSuccess={() => onSuccess()}
          />
        ) : (
          <LoginForm
            options={options}
            onCheck={onCheck}
            apiPath={apiPath}
            dispatch={dispatch}
            onSignUp={onSignUp}
            onSuccess={() => onSuccess()}
          />
        )}
      </div>
    </div>
  );
};
