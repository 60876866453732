import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Delete from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CountWidget from "./CountWidget";
import {calcPrice, renderNameVertical} from "../../../utils";
import {prettyDateUSA, dateToLocal} from "commons-ui/core/utils/date";
import "./style.styl";


export default function CartContent(props){
    const {items, deliveryType, type, isMobile, isOpen, onClose, onNextStep, drawerClassName, mediaPath, noImage, noWatermark, dispatch} = props;

    const total = items.reduce((a, b) => a + (calcPrice(b.product || {}, b.featureList).final_price || 0) * (b.count || 0), 0).toFixed(2);
    return (
        <div className="u-flexColumn u-sizeFullHeight">
            <span className="u-fontWeightBold u-fontSize18">Order Details</span>
            <List className="cart-content u-paddingTop0">
                {
                    items.map((item, i) => {
                        const featureDict = item.featureDict || {};
                        const featureList = item.featureList || [];
                        const product = item.product || {};
                        const {price, price_fixed, discount_fixed, discount_percent} = calcPrice(product, featureList);
                        const path = product.images ? mediaPath + (product.images || "").split(",")[0] : (noImage || "https://assets.smallcase.com/images/smallcases/200/SCSB_0004.png");
                        const cartLimit = parseInt(product.cart_limit || "0") || 0;
                        return (
                            <ListItem key={i} className="u-padding0">
                                <div className="u-flexCenter u-sizeFullWidth u-paddingVertical10 u-paddingRight15">
                                    <div className="u-relative u-inlineBlock u-marginVertical4 u-verticalAlignTop u-marginRight10">
                                        <div className={"image-widget-item u-relative u-width80 u-height80 u-borderRadius8"}
                                             style={{backgroundImage: `url(${path})`, backgroundSize: "cover"}}></div>
                                    </div>
                                    <div className="u-sizeFullWidth u-flexColumn">
                                        <div className="u-flexColumn u-fontSize16 u-textColorDarker">
                                            <span className="u-marginRight10">{renderNameVertical(product, {featureList})}</span>

                                            {
                                                product.product_available_from || product.product_available_till ?
                                                    <div>
                                                        <div className={"u-fontSize8 u-paddingTop5 u-textColorRed "}>
                                                            Available for fulfillment only on these dates
                                                            {
                                                                product.product_available_from ? (" " + prettyDateUSA(new Date(product.product_available_from)) + "") : ""
                                                            }
                                                            - 
                                                            {
                                                                product.product_available_till ? (" " + prettyDateUSA(new Date(product.product_available_till)) + "") : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                        {
                                            deliveryType === "shipping_event" ?
                                                <div className={"u-fontSize15 u-textColorDarker u-flexCenter "}>
                                                    <div    className={"u-flexCenter u-fontSize15"}>
                                                            qty: {item.count || 0}
                                                    </div>
                                                </div>
                                                :
                                                <div className={"u-fontSize15 u-textColorDarker u-flexCenter "}>
                                                    <div className="u-flexCenter">
                                                        <div    className={"u-flexCenter u-fontWeightBold u-fontSize15"}>
                                                            $ {discount_percent ? discount_fixed : price_fixed}
                                                        </div>
                                                        {
                                                            discount_percent ?
                                                                <div className="u-flexCenter u-marginLeft10">
                                                                    <div    className={"u-flexCenter u-fontSize9"}
                                                                            style={{color: "#9d9ca5", textDecoration: "line-through"}}>
                                                                        $ {price_fixed}
                                                                    </div>
                                                                    <div className="discount_percent u-marginLeft5">
                                                                        {discount_percent}%
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    <div className={"u-flexCenter u-marginLeft20 "}>
                                                        {
                                                            product.slug === "piroshky_celebration_pack" || product.slug === "piroshky_summer_pack" ?
                                                                null
                                                                :
                                                                <CountWidget itemIdValue={item.id} value={item.count || 0} dispatch={dispatch} limit={cartLimit}/>
                                                        }
                                                        
                                                        <div className="u-flexCenter u-marginLeft5">
                                                            <IconButton className="u-padding2 u-opacity80" onClick={() => dispatch("cart", {id: item.id}, "remove_product")}>
                                                                <Delete className="u-fontSize15"/>
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                        
                                    </div>
                                </div>
                            </ListItem>
                        );
                    })
                }
            </List>
            <div className="cart-actions u-marginBottom100">
                <div className="u-marginTop10 u-flexCenter u-justifyContentStart u-borderTop1 u-borderColorLightest u-paddingRight15 u-paddingVertical10">
                    <div className="u-marginVertical10">
                        <div className="u-fontSize17">Total: <span className="u-fontWeightBold">$ {total}</span></div>
                    </div>
                </div>
                <Button className="button-secondary" size="large" onClick={onNextStep}>
                    Continue
                </Button>
            </div>
        </div>
    );
}
