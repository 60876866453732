import React, {useState, useEffect, useMemo} from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import TextField from "@material-ui/core/TextField";
import {dataSourceV2} from "commons-ui/core/DataSource";
import MuiSelect from "commons-ui/core/MuiSelect";
import Loading from "commons-ui/core/Loading";
import renderHTML from "react-render-html";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutline from "@material-ui/icons/HelpOutline";
import { calcDeliveryCost } from "../index";
import {KeyboardDatePicker, DatePicker} from "@material-ui/pickers";
import {getRequestedTime, getMinDate} from "../utils";
import "./style.styl";




export default function PickUpForm(props) {
    const {values, description, itemId, modelName, itemIdParent, itemIdValueParent, readOnly, readOnlySelect, errors, touched, onBack, setFieldValue, isSubmitting, apiPath} = props;
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);


    const request = () => {
        dataSourceV2(`${modelName}_list?${itemIdParent}=${itemIdValueParent}`, {url: apiPath})
            .then(v=> {
                if (!v.message && v.item_list && v.item_list.length) {
                    setItems(v.item_list);
                }
                setLoading(false);
            });
    };

    const onSelectItem = (value) => {
        setFieldValue(itemId, value);
        setFieldValue("delivery_requested_date", null);
        setFieldValue("delivery_requested_time", "");
    };


    const item = useMemo(() => {
        const itemList = items.filter(v => v[itemId] === values[itemId]);
        if (itemList.length) return itemList[0];
        return {};
    }, [values[itemId], items.length]);


    useEffect(() => {
        if (itemIdValueParent) request();
    }, [itemIdValueParent]);

    useEffect(() => {
        return
        setFieldValue("min_order", item.min_order || props.item.min_order || 0);
    }, [props.item.min_order, item.min_order]);

    useEffect(() => {
        return
        const cost = calcDeliveryCost(values.base_amount, item.delivery_cost_json, item.cost || props.item.cost || 0)
        setFieldValue("delivery_amount", cost);
    }, [item.cost, props.item.cost, item.delivery_cost_json, values.base_amount]);

    useEffect(() => {
        return
        setFieldValue("packaging_amount", item.packaging_cost || props.item.packaging_cost || 0);
    }, [item.packaging_cost, props.item.packaging_cost]);

    useEffect(() => {
        return
        setFieldValue("tax_rate_global", item.tax_rate_percent || 0);
    }, [item.tax_rate_percent]);

    useEffect(() => {
        return
        if (item.has_labeling_cost && item.labeling_cost) setFieldValue("labeling_cost", item.labeling_cost || 0);
    }, [item.has_labeling_cost, item.labeling_cost]);

    useEffect(() => {
        setFieldValue("ecommerce_store_delivery_option_pickup", item);
    }, [Object.keys(item).length]);

    if (loading) {
        return (
            <Loading className="u-marginBottom100"/>
        );
    }

    const disableDays = (date) => {
        try {
            const days = (item.requested_date_disabled_days_of_week || "").split(";");
            for (let i = 0; i < days.length; i++ ) {

                let convertedDay = parseInt(days[i]) + 1;
                if (convertedDay === 7){
                    convertedDay = 0;
                }
                if (date.getDay() === convertedDay ){
                    return true;
                }
            }

            if (item.requested_date_disabled_start_at && item.requested_date_disabled_end_at) {
                const startAt = new Date(item.requested_date_disabled_start_at);
                startAt.setHours(0);
                startAt.setMinutes(0);
                const endAt = new Date(item.requested_date_disabled_end_at);
                endAt.setHours(23);
                endAt.setMinutes(59);
                if (date.getTime() >= startAt.getTime() && date.getTime() <= endAt.getTime()) {
                    return true;
                }
            }
        } catch {
            console.log("disable date error");
        }

        return false;
      };

    const shift = item.delivery_requested_date_shift_minutes || props.item.delivery_requested_date_shift_minutes;

    return (
            <React.Fragment>
                <div className="pickup-delivery-form u-flex u-justifyContentSpaceBetween u-xs-flexColumn">
                    <div className="u-sizeFullWidth u-maxWidth400">
                        {
                            !readOnly && !readOnlySelect ?
                                <div style={{color:"red"}}>Please Select a Store Below</div>
                                :
                                null
                        }

                        <List className="">
                            {
                                items.map((v, i) => {
                                    if ((readOnly || readOnlySelect) && v[itemId] !== values[itemId]) {
                                        return null;
                                    }
                                    const selected = v[itemId] === values[itemId];
                                    return (
                                        <ListItem   selected={selected} button  key={i} className="u-textColorNormal u-fontSize12"
                                                    onClick={() => readOnly || readOnlySelect ? null : onSelectItem(selected ? "" : v[itemId])}>
                                                <div>
                                                    <div className="u-fontWeightBold">
                                                        {v.name}
                                                    </div>
                                                    <div className=" u-fontSize9">{v.address}{v.city ? ", " + v.city : ""}{v.state ? ", " + v.state : ""}{v.postal_code ? ", " + v.postal_code : ""}</div>
                                                </div>
                                        </ListItem>
                                    );
                                    return (
                                        <ListItem   selected={selected} button key={i} className="u-textColorNormal u-fontSize12"
                                                    onClick={() => readOnly ? null : setFieldValue(itemId, selected ? "" : v[itemId])}>
                                            {v.name}
                                        </ListItem>
                                    );
                                })
                            }
                        </List>

                        {
                            errors[itemId] && touched[itemId] &&
                                <div className="MuiFormHelperText-root Mui-error u-marginBottom15">
                                    {errors[itemId]}
                                </div>
                        }

                        <DatePicker
                          disableToolbar
                          disablePast
                          allowKeyboardControl
                          shouldDisableDate={disableDays}
                          variant="inline"
                          className="u-sizeFullWidth u-marginVertical12"
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          margin="dense"
                          id="date-picker-inline"
                          label="Requested Date"
                          disabled={readOnly}
                          minDate={getMinDate(shift, item.delivery_requested_time_options, item.delivery_requested_time_options_interval)}
                          error={errors.delivery_requested_date && touched.delivery_requested_date}
                          helperText={(errors.delivery_requested_date && touched.delivery_requested_date) ? errors.delivery_requested_date : undefined}
                          value={values.delivery_requested_date ? new Date(values.delivery_requested_date) : null}
                          onChange={(date) => {
                            !date || isNaN(date.getTime()) ? null : setFieldValue("delivery_requested_date", date.toISOString())
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />

                        {
                            values.delivery_requested_date ?
                                <MuiSelect              items={getRequestedTime(item.delivery_requested_time_options, new Date(values.delivery_requested_date), shift, item.delivery_requested_time_options_interval)}
                                                        selected={values.delivery_requested_time}
                                                        htmlFor={"delivery_requested_time_options_id"}
                                                        className="u-minWidth240 u-sizeFullWidth u-marginVertical12"
                                                        label="Requested Time"
                                                        disabled={readOnly}
                                                        onSelect={(value) => setFieldValue("delivery_requested_time", value)}
                                />
                                :
                                null
                        }

                        <TextField
                            label="Comments (Special Instructions)"
                            className="u-sizeFullWidth u-marginVertical12 u-hide"
                            margin="dense"
                            multiline
                            rows={2}
                            disabled={readOnly}
                            error={errors.customer_note && touched.customer_note}
                            helperText={errors.customer_note && touched.customer_note ? errors.customer_note : ""}
                            onChange={(e) => setFieldValue("customer_note", e.target.value)}
                            value={values.customer_note}
                            variant="outlined"
                        />



                        {
                            item.min_order || props.item.min_order ?
                                <div className="u-flexColumn u-marginTop8 u-fontSize10 u-sizeFullWidth">
                                    <span className="u-flexCenter">
                                        Min. Order: {"$" + (item.min_order ? parseFloat(item.min_order) : (props.item.min_order ? parseFloat(props.item.min_order) : 0).toFixed(2))}
                                        <Tooltip title={"Min. order of in-house items before tax"}>
                                            <HelpOutline fontSize="small" className="u-textColorNormal u-marginLeft5"/>
                                        </Tooltip>
                                    </span>

                                    <span>{errors.min_order && touched.min_order ? <div className="MuiFormHelperText-root Mui-error">{errors.min_order}</div> : ""}</span>
                                </div>
                                :
                                null
                        }

                        {
                            item.max_order ?
                                <div className="u-marginTop8 u-fontSize10 u-sizeFullWidth">
                                    Max. Order: {"$" + (parseFloat(item.max_order) || 0).toFixed(2)}
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className="u-sizeFullWidth u-maxWidth400">
                        <div className="u-textColorNormal u-marginTop8 u-xs-marginTop25 u-fontSize13">
                            {(item.description || description) && renderHTML(item.description || description)}
                        </div>
                    </div>
                </div>


            </React.Fragment>
    );
}
