import React, {useState, useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Loyalty from "@material-ui/icons/Loyalty";
import {dataSourceV2} from "commons-ui/core/DataSource";
import Loading from "commons-ui/core/Loading";
import Checkbox from "@material-ui/core/Checkbox";
import {addParamToQueryString} from "commons-ui/core/utils";
import "./style.styl";


export default function GiftCard(props) {
    const {cart, values, errors, ecommerceStoreId, onAction, getEarnPoints, noFree, mode, login, setValues, setFieldError, setFieldValue, mediaPath, apiPath, dispatch, location} = props;
    const [promocode, setPromocode] = useState("")
    const [input, setInput] = useState("")
    const [error, setError] = useState("")
    const [giftCard, setGiftCard] = useState("")
    const [loading, setLoading] = useState(false)
    const [selecting, setSelecting] = useState(false)
    const [balance, setBalance] = useState(0)
    const [pointsBalance, setPointsBalance] = useState(0)
    const [redeemValue, setRedeemValue] = useState(null)
    const [redeemError, setRedeemError] = useState("")
    const [isApplied, setIsApplied] = useState(false)

    const [giftCardLoading, setGiftCardLoading] = useState(false)
    const [giftCardBalance, setGiftCardBalance] = useState(0)
    const [isGiftCardApplied, setIsGiftCardApplied] = useState(false)
    const [giftCardRedeemValue, setGiftCardRedeemValue] = useState(null)
    const [giftCardError, setGiftCardError] = useState("")
    const [giftSelected, setGiftSelected] = useState(undefined)

    const isCard = (value) => /^(?=.*\d)[\d ]+$/.test(value) && value.length > 12

    console.log("values:", values);


    const onDiscountCheck = () => {
        setFieldValue("promocode", promocode)
        

        return
        const now = new Date();
        const date = values.delivery_type === "event" ? values.event_date_selected : values.delivery_requested_date;
        dataSourceV2(`ecommerce_store_promocode_check?ecommerce_store_id=${ecommerceStoreId}&code=${value}&order_placed_at=${now.toISOString()}&delivery_requested_at=${date}`, {url: apiPath})
            .then(v => {
                if (v.message) {
                    setError(v.message);
                    setValues({...values, ecommerce_store_promocode: {}, ecommerce_store_promocode_discount_percent: 0, ecommerce_store_promocode_discount: 0});
                    console.log("ERROR");
                } else if (v.item) {
                    setError("");
                    setFieldValue("ecommerce_store_promocode", v.item);
                    setFieldValue("ecommerce_store_promocode_code", value)
                    if (v.item.discount_percent) {
                        setFieldValue("ecommerce_store_promocode_discount_percent", v.item.discount_percent);
                    } else if (v.item.discount) {
                        setFieldValue("ecommerce_store_promocode_discount", v.item.discount);
                    }
                    setInput("")
                    setError("")
                }
            });
    };

    const validateRedeemValue = (value, bal) => {
      if (value) {
          value = parseFloat(value)
          if (value > (bal || balance)) {
            value = bal || balance
          }
          if (value >= values.total_amount) {
            value = values.total_amount
          } else {
            value = Math.floor(value * 100) / 100
          }
          if (value < 0) {
              value = 0
          }
      } else {
          value = 0
      }
      value = parseFloat(value.toFixed(2))
      return value
    }

    const onCardNumber = (number, isGiftCard) => {
      number = number.split(" ").join("")

      if (!isCard(number)) {
        return
      }

      if (values.ecommerce_store_gift_card_list.filter(v => v.number === number).length > 0) {
            if (isGiftCard) {
                setGiftCardError("Card already applied")
            } else {
                setError("Card already applied")
            }
          return
      }

      // Remove ------
      //if (number === "7150011021720901" || number === "7150011021732382") {
      //    const balance = number === "7150011021720901" ? 23.46 : 51
      //    setIsApplied(true)
      //    setBalance(balance)
      //    setPointsBalance(number === "7150011021720901" ? 37 : 22)
      //    setRedeemValue(validateRedeemValue(balance))
      //} else {
      //    setError("Invalid card")
      //}
      //return
      // Remove END -----
      if (isGiftCard) {
        setGiftCardLoading(true)
      } else {
        setLoading(true)
      }
      
      dataSourceV2(`ecommerce_store_gift_card_balance_check?number=${number}`, {url: apiPath})
          .then(v => {
            if (isGiftCard) {
                if (v.message) {
                    setGiftCardError(v.message);
                    setIsGiftCardApplied(false)
                    setGiftCardBalance(balance)
                    setGiftCardRedeemValue(null)
                } else if (v.balance !== undefined) {
                    let balance = parseFloat(v.balance || "0")
                    setIsGiftCardApplied(true)
                    setGiftCardBalance(balance)
                    setGiftCardRedeemValue(validateRedeemValue(balance, balance))
                }
            } else {
                if (v.message) {
                    setError(v.message);
                    setIsApplied(false)
                    setBalance(balance)
                    setPointsBalance(pointsBalance)
                    setRedeemValue(null)
                } else if (v.balance !== undefined) {
                    let balance = parseFloat(v.balance || "0")
                    const pointsBalance = parseInt(v.points_balance || "0")
                    setIsApplied(true)
                    setBalance(balance)
                    setPointsBalance(pointsBalance)
                    setRedeemValue(validateRedeemValue(balance, balance))
                }
            }
              
            setLoading(false)
            setGiftCardLoading(false)
          });
    }

    const onReset = () => {
        setIsApplied(false)
        setRedeemValue(0)
        setBalance(0)
        setPointsBalance(0)
        setInput("")
        setError("")
    }

    const onGiftCardReset = () => {
        setIsGiftCardApplied(false)
        setGiftCardRedeemValue(0)
        setGiftCardBalance(0)
        setGiftCard("")
        setGiftCardError("")
    }

    const onCardRedeem = () => {
        if (!giftCardRedeemValue) {
          return
        }
        const valueValidated = validateRedeemValue(giftCardRedeemValue)
        if (valueValidated.toFixed(2) !== giftCardRedeemValue.toFixed(2)) {
          //setRedeemValue(valueValidated)
          //return
        }

        const cardList = values.ecommerce_store_gift_card_list
        cardList.push({
            number: giftCard,
            balance: giftCardBalance,
            amount: giftCardRedeemValue
        })
        setFieldValue("ecommerce_store_gift_card_list", cardList)
        setIsGiftCardApplied(false)
        setGiftCardRedeemValue(0)
        setGiftCardBalance(0)
        setGiftCard("")
        setGiftCardError("")
        setTimeout(() => onAction ? onAction("gift_card_applied") : null, 200)
    }

    const onRedeemValueChange = (value) => {
      setRedeemValue(value || null)
    }

    const onGiftCardRedeemValueChange = (value) => {
        setGiftCardRedeemValue(parseFloat(value) || null)
      }

    const onGiftCardApply = (value) => {
        if (!value) {
            onGiftCardReset()
        }
        value = value.split(" ").join("")
        if (isCard(value)) {
            onCardNumber(value, true)
        } else {
            //onDiscountCheck(value)
        }
    }

    const onApply = (value) => {
        if (!value) {
            onReset()
        }
        value = value.split(" ").join("")
        if (isCard(value)) {
            onCardNumber(value)
        } else {
            //onDiscountCheck(value)
        }
    }

    const requestLoyaltyCard = () => {
        dataSourceV2("loyalty_card_get", {url: apiPath})
            .then(v => {
                if (v.card && v.card.number) {
                    const number = v.card.number
                    setInput(number)
                    setTimeout(() => onApply(number), 200)
                }
            });
    }


    const getGiftList = () => {
        const result = [];
        cart.items.forEach((item, i) => {
            if (item.id.endsWith("__free")) {
                return
            }
            const product = item.product
            const free = item.free || 0
            const qty = item.count || item.qty || item.quantity || 1
            for (let i = 0; i < qty; i++) {
                if (!product.price_points || product.price_points === "0") {
                    continue
                }
                result.push({
                    id: item.id,
                    image: product.images ? mediaPath + (product.images || "").split(",")[0] : "",
                    name: product.name,
                    price: parseInt(product.price_points || "25") || 25,
                    product,
                    qty: qty,
                    index: i + 1,
                    is_selected: !!free && free >= (i + 1)
                })   
            }
        });
        //[10, 15, 30].forEach((v, i) => {
        //    result.push({
        //        id: v.toString(),
        //        name: `${v}% discount`,
        //        discount: v,
        //        price: v
        //    })
        //});
        return result
    }

    const onGiftSelect = (gift) => {
        setSelecting(true)
        setTimeout(() => setSelecting(false), 1000)
        console.log("gift select", gift)
        const id = gift.id || ""
        const isDiscount = gift.discount || 0
        const price = gift.price || 0

        


        //setFieldValue("ecommerce_store_gift_card_list", []);
        //cart.items.forEach(item => {
        //    if (item.id.endsWith("__free")) {
        //        dispatch("cart", { id: item.id }, "remove_product");
        //    }
        //})

        if (id === giftSelected) {
            setGiftSelected(undefined)
            //return
        } else {
            setGiftSelected(id)
        }
        

        if (isDiscount) {
            const discount = gift.discount || 0
            const amount = values.base_amount * discount / 100
            const cardList = [{
                name: "Discount " + discount.toString() + "%",
                number: input.split(" ").join(""),
                balance: pointsBalance,
                amount: amount,
                discount
            }]
            setFieldValue("ecommerce_store_gift_card_list", cardList)
        } else {
            const product = {...gift.product}
            const id = product.ecommerce_product_id || product.id
            //product.name = product.name += " FREE"
            //product.price = 0
            //product.ecommerce_product_id = product.ecommerce_product_id + "__free"
            //dispatch("cart", { product: product, count: 1, ecommerceStoreId, deliveryItem: cart.deliveryItem}, "add_product_without_cart");
            const freeNew = (cart.items.filter(v => v.id === id)[0].free || 0) + (gift.is_selected ? -1 : 1)
            dispatch("cart", { id, free: freeNew }, "update_final_price");
        }
    }

    useEffect(() => {
        if (error || values.promocode_error) {
            setError("")
            setGiftCardError("")
            setFieldValue("promocode_error", "")
        }
    }, [input])

    useEffect(() => {
        requestLoyaltyCard()
        dispatch("cart", {}, "remove_final_price");
    }, [login.token])


    const finalGiftList = getGiftList()
    let pointsBalanceSelected = 0;
    finalGiftList.forEach(v => {
        if (v.is_selected) {
            pointsBalanceSelected += v.price
        }
    })

    const promocodeWidget = (
        <div className={"u-sizeFullWidth u-flex u-alignItemsStart u-marginTop15 " + (mode && mode !== "promocode" ? "u-hide" : "")}>
              <TextField
                  error={values.promocode_error}
                  label="Promocode"
                  margin="dense"
                  helperText={(values.promocode_error) ? (values.promocode_error) : ""}
                  className="u-sizeFullWidth u-textAlignCenter"
                  onChange={(e) => setPromocode(e.target.value)}
                  value={promocode}
                  variant="outlined"
              />
              <span>
                  <Button className="button-grey u-marginLeft10 u-marginTop6" size="large" onClick={() => onDiscountCheck()}>
                      Apply
                  </Button>
              </span>
          </div>
    )

    const giftCardWidget = (
        <>
            <div className={"u-sizeFullWidth u-flex u-alignItemsStart u-marginTop10 " + (mode && mode !== "gift" ? "u-hide" : "")}>
                <TextField
                    error={giftCardError}
                    label="Gift Card"
                    margin="dense"
                    helperText={giftCardError ? giftCardError : (isGiftCardApplied ? <span style={{color: "#1b5f06"}}>Gift Card {values.ecommerce_store_gift_card_number} is Valid</span> : "")}
                    className="u-sizeFullWidth u-textAlignCenter"
                    onChange={(e) => setGiftCard(e.target.value)}
                    value={giftCard}
                    variant="outlined"
                />
                <span>
                    <Button className="button-grey u-marginLeft10 u-marginTop6" size="large" onClick={() => onGiftCardApply(giftCard)}>
                        Check 
                    </Button>
                </span>

                
            </div>
            {
                giftCardLoading ?
                    <Loading className="u-marginTop30"/>
                    :
                    null
            }
            {
                isGiftCardApplied ?
                    <div className="u-sizeFullWidth u-flexColumn u-marginTop10">
                        <span className="u-marginRight15 u-marginBottom10" style={{color: "#1b5f06"}}>Available Cash Balance: <span className="u-fontWeightBold">${giftCardBalance}</span></span>

                        <div className="u-sizeFullWidth u-flexCenter u-xs-marginTop8 u-xs-sizeFullWidth">
                            <TextField
                                label="Redeem Amount"
                                type="number"
                                margin="dense"
                                disabled={!giftCardBalance}
                                className=" u-xs-maxWidthNone"
                                onChange={(e) => onGiftCardRedeemValueChange(e.target.value)}
                                onBlur={(e) => onGiftCardRedeemValueChange(validateRedeemValue(e.target.value))}
                                value={giftCardRedeemValue}
                                variant="outlined"
                            />
                            <span>
                                <Button className="button-secondary u-marginLeft10" disabled={!giftCardRedeemValue || giftCardRedeemValue <= 0 || !giftCardBalance} size="medium" onClick={() => onCardRedeem()}>
                                    Redeem
                                </Button>
                            </span>
                        </div>
                    </div>
                    :
                    null
            }
          </>
    )

    if (mode === "promocode") {
        return (
            <div className="u-flexColumn u-sizeFullWidth u-marginTop10">
                <span className="u-fontWeightBold u-fontSize18">Promocode</span>
                {promocodeWidget}
            </div>
        )
    }

    if (mode === "gift") {
        return (
            <div className="u-flexColumn u-sizeFullWidth u-marginTop10">
                <span className="u-fontWeightBold u-fontSize18">Gift Card</span>
                {giftCardWidget}
            </div>
        )
    }

    const isLastAvailable = finalGiftList.filter((gift, i) => !gift.is_selected).length === 1


    return (
      <div className="u-flexColumn u-sizeFullWidth u-marginTop10">
        {
            isApplied ?
                <span className="u-fontWeightBold u-fontSize18">You have <span style={{color: "#1b5f06"}}>{pointsBalance}</span> Points</span>
                :
                <span className="u-fontWeightBold u-fontSize18">Loyalty</span>
        }
        


            {
                loading ?
                    <Loading className="u-marginTop30"/>
                    :
                    null
            }
          {
            isApplied ?
                <div className="u-flexCenter u-flexColumn u-sizeFullWidth u-justifyContentSpaceBetween u-border1 u-borderColorLighter u-borderRadius10 u-paddingVertical10 u-marginTop12">
                    {/*<div className="u-sizeFullWidth u-flexCenter u-justifyContentSpaceBetween u-xs-flexColumn">
                        <span className="u-marginRight15" style={{color: "#1b5f06"}}>Available Cash Balance: <span className="u-fontWeightBold">${balance}</span></span>

                        <div className="u-flexCenter u-xs-marginTop8 u-xs-sizeFullWidth">
                            <TextField
                                label="Amount"
                                type="number"
                                margin="dense"
                                disabled={!balance}
                                className="u-maxWidth100 u-xs-maxWidthNone"
                                onChange={(e) => onRedeemValueChange(e.target.value)}
                                onBlur={(e) => onRedeemValueChange(validateRedeemValue(e.target.value))}
                                value={redeemValue}
                                variant="outlined"
                            />
                            <span>
                                <Button className="button-secondary u-marginLeft10" disabled={!redeemValue || redeemValue <= 0 || !balance} size="medium" onClick={() => onCardRedeem()}>
                                    Redeem
                                </Button>
                            </span>
                        </div>
                    </div>*/}

                    <div className="u-sizeFullWidth" style={{display: "flex", flexDirection: "column"}}>
                    <span className="u-paddingBottom10 u-paddingHorizontal10 u-borderBottom1 u-borderColorLighter" >You can use your points to redeem one of the following items from your cart today:</span>
                        {/*<span className="u-marginRight15 u-marginBottom10 u-paddingHorizontal10" style={{color: "#1b5f06"}}>Congratulations you have <span className="u-fontWeightBold u-textColorRed">{pointsBalance}</span> loyalty points to redeem one of rewards below</span>*/}
                        <span style={{maxHeight: "200px", overflow: "scroll"}}>
                            {
                                finalGiftList.length === 0 ?
                                    <div className="u-padding20 u-fontSize12 u-textColorLightest" style={{color: "#414141"}}>
                                        None of your items in your cart apply for the loyalty redemption. Please add other items to use your points. To see which items are available and for more information please visit our <a href="/faq" target="_blank" className="link">FAQ page</a>.
                                    </div>
                                    :
                                    null
                            }
                            {
                                finalGiftList.map((gift, i) => {
                                    const image = gift.image
                                    const price = gift.price
                                    const product = gift.product || {}
                                    if (!price) {
                                        return null
                                    }
                                    const isSelected = gift.is_selected//giftSelected === gift.id
                                    const isDisabled = !isSelected && ((!pointsBalance || price > (pointsBalance - pointsBalanceSelected)) || (noFree && isLastAvailable))

                                    return (
                                        <div key={i} onClick={() => isDisabled || selecting ? null : onGiftSelect(gift)} className={"gift-item  u-paddingHorizontal10 u-sizeFullWidth u-flex u-alignItemsCenter " + (isSelected ? " gift-item-selected" : "") + (isDisabled || selecting ? " disabled" : " u-cursorPointer")}>
                                            <Checkbox
                                                color="secondary"
                                                checked={isSelected}
                                                className="u-marginRight5 u-marginLeftNegative5"
                                            />
                                            {
                                                image ?
                                                    <div className="u-relative u-inlineBlock u-marginVertical4 u-verticalAlignTop u-marginRight10">
                                                        <div className={"image-widget-item u-relative u-width50 u-height50 u-borderRadius8"}
                                                        style={{backgroundImage: `url(${image})`, backgroundSize: ""}}></div>
                                                    </div>
                                                    :
                                                    <div className="u-flexCenter u-justifyContentCenter u-width50 u-height50 u-border1 u-borderRadius8 u-marginVertical4 u-marginRight10 u-borderColorLighter">
                                                        <Loyalty className="u-fontSize32 u-textColorRed"/>
                                                    </div>
                                            }
                                            
                                            <span className="u-flexColumn">
                                                <div>
                                                    {gift.name}
                                                </div>
                                                <div className="u-fontSize10">
                                                    price: <span className="u-fontWeightBold">{price}</span> points {isDisabled ? <span className="u-textColorRed">unavailable</span> : (isSelected ? <span className="u-marginLeft8"><span style={{textDecoration: "line-through", color: "red"}}>${product.price}</span> <span className="u-fontWeightBold" style={{color: "#1b5f06"}}>FREE</span></span> : null)}
                                                </div>
                                            </span>
                                        </div>
                                    )
                                })
                            }
                        </span>

                        <span className=" u-paddingHorizontal10 u-paddingTop10 u-borderTop1 u-borderColorLighter">Redeeming <span className="u-fontWeightBold">{pointsBalanceSelected || 0}</span> out of <span className="u-fontWeightBold">{pointsBalance}</span> points</span>
                        {
                            isLastAvailable ?
                                <span className="u-paddingHorizontal10 u-opacity80 u-fontSize9 u-textColorRed">Redeeming all products isn’t available in this quick checkout. To place your order for free, please click the Credit Card button.</span>
                                :
                                null
                        }
                    </div>


                    <div className="u-hide u-marginTop15 u-sizeFullWidth u-flexColumn u-justifyContentSpaceBetween u-xs-flexColumn">
                        <span className="u-marginRight15">Loyalty Points Balance: <span className="u-fontWeightBold">{pointsBalance}</span></span>
                        <span className="u-marginRight15 u-marginTop5">Loyalty You will Earn from this Order: <span className="u-fontWeightBold">{getEarnPoints()}</span></span>
                        <span className="u-fontSize10 u-marginTop10" style={{color: "#6a6a6a"}}>You can redeem cash balance now or you can earn loyalty points from this order</span>
                    </div>
                </div>
                :
                (
                    loading ? 
                        null 
                        :
                        <span className="u-marginTop10 u-marginBottom15 u-border1 u-borderColorLighter u-borderRadius10 u-padding15">
                            <span>
                                You could be earning <span className="u-fontWeightBold">{getEarnPoints()}</span> points. Create/log in to earn loyalty points
                            </span>
                            <span className="u-flexCenter u-justifyContentCenter u-marginTop5">
                                <Button className="button-secondary" size="small" onClick={() => dispatch(addParamToQueryString("auth_dialog", "true", location))}>
                                    Sign Up for Loyalty
                                </Button>
                            </span>
                        </span>
                )
          }


            {promocodeWidget}

            {giftCardWidget}
      </div>
    );

}
