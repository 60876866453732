import React from "react";
import { Form, withFormik } from "formik";
import cookie from "react-cookie";

import { useStore } from "../../../../context/state";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { dataSourceV2 } from "commons-ui/core/DataSource";

import "./LoginForm.module.scss";

export const emailRe =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function LoginForm(props) {
  const { values, errors, touched, isSubmitting, onSignUp, setFieldValue } = props;

  const { dispatch } = useStore();

  return (
    <Form
      noValidate=""
      className=""
      autoComplete="off"
    >
      <div className="u-flexColumn u-alignItemsCenter u-sizeFullWidth">
        <div className="u-flexColumn u-alignItemsCenter u-marginBottom15">
          <div className="u-fontSize22 u-fontWeightBold u-textColorDark">
            Login
          </div>
          <div className="comment">
            Dont have an account?{" "}
            <span
              className="link"
              link-meta="/signup"
              onClick={() => onSignUp()}
            >
              Sign Up
            </span>
          </div>
          
        </div>
        <div className="u-maxWidth400">
          <TextField
            error={errors.email && touched.email}
            label="Email  "
            helperText={errors.email && touched.email ? errors.email : ""}
            margin="dense"
            variant="outlined"
            autoComplete="off"
            autoComplete="new-password"
            className="u-sizeFullWidth u-marginVertical12"
            onChange={(e) => setFieldValue("email", e.target.value)}
            value={values.email}
          />
          <TextField
            error={errors.password && touched.password}
            label="Password  "
            helperText={
              errors.password && touched.password ? errors.password : ""
            }
            margin="dense"
            variant="outlined"
            type="password"
            autoComplete="off"
            autoComplete="new-password"
            className="u-sizeFullWidth u-marginVertical12"
            onChange={(e) => setFieldValue("password", e.target.value)}
          />
        </div>

        <div className="comment u-marginTop5 u-textAlignCenter u-hide">
            Forgot your password?{" "}
            <span className="link" onClick={() => dispatch("/reset-password")}>
              Reset Password
            </span>
          </div>

        <div className="u-marginTop20">
          <Button
            variant="outlined"
            type="submit"
            size="large"
            className="button-secondary u-minWidth120"
            disabled={isSubmitting}
          >
            Login
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default LoginForm = withFormik({
  mapPropsToValues: (props) => ({
    email: props.options ? props.options.email || "" : "",
    password: "",
  }),

  validate: (values, props) => {
    let errors = {};
    if (!emailRe.test(values.email)) {
      errors.email = "Incorrect email";
    }
    if (!values.password) {
      errors.password = "Incorrect passord";
    }
    return errors;
  },

  handleSubmit: (values, props) => {
    const { dispatch, path, onSuccess, onCheck, apiPath } = props.props;
    const { setFieldError } = props;

    dataSourceV2(path || "sign_in", {
      data: {
        ...values,
        email: values.email.toLowerCase(),
        device_id: cookie.load("device_id") || "",
      },
      url: apiPath,
    }).then((v) => {
      props.setSubmitting(false);
      if (v.message) {
        const err = v.message || "";
        if (err.includes("cred")) {
          setFieldError("email", "Invalid Credentials");
        } else if (err.includes("role")) {
          setFieldError("email", "Incorrect role");
        } else {
          setFieldError("email", "Try again later");
        }
        return;
      }
      const token = v.access || v.token;
      if (token) {
        cookie.save("token", token, { path: "/" });
        if (v.refresh) {
          cookie.save("token_refresh", v.refresh, { path: "/" });
        }
        dispatch("login", { token: token });
        props.setSubmitting(false);
        onSuccess();
      } else if (v.device_id) {
        cookie.save("device_id", v.device_id, { path: "/" });
        onCheck();
      } else {
        const err = v.message || "";
        if (err.includes("incorrect email")) {
          setFieldError("email", "Incorrect email");
        } else if (err.includes("incorrect password")) {
          setFieldError("password", "Incorrect password");
        } else {
          setFieldError("email", "Try again later");
        }
      }
    });
  },
})(LoginForm);
