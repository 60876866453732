import React, {useState, useEffect} from "react";
import queryString from "query-string";
import { useRouter } from "../../hooks/useRouter";

import LoginView from "./Login";
import SignUpView from "./SignUp";

import { useStore } from "../../context/state";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { deleteParamFromQueryString } from "commons-ui/core/utils";

export const AuthDialog = ({onSuccess}) => {
  const [tab, setTab] = useState("signup");
  const { isMobile, dispatch } = useStore();

  const location = useRouter();
  const params = queryString.parse(location.search);

  const isOpen = !!params.auth_dialog;

  const defaultEmail = params.auth_dialog && params.auth_dialog.includes("@") && params.auth_dialog.includes("__") ? (params.auth_dialog.split("__")[1] || "") : "";

  const onClose = () => {
    dispatch(deleteParamFromQueryString("auth_dialog", location));
  };

  useEffect(() => {
    if (params.auth_dialog && params.auth_dialog.startsWith("login")) {
      setTab("login");
    }
  }, [params.auth_dialog])

  console.log("defaultEmail", defaultEmail, tab, isOpen)

  return (
    <Dialog
      fullScreen={false}
      open={isOpen}
      maxWidth="sm"
      onClose={onClose}
      fullWidth
      className=""
      scroll="paper"
    >
      <div className="u-flexCenter u-justifyContentSpaceBetween">
        <DialogTitle>{tab === "signup" ? "Sign Up" : "Login"}</DialogTitle>
        <div>
          <Tooltip title="Close">
            <IconButton className="u-marginRight8" onClick={onClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <DialogContent dividers>
        <div className="u-paddingBottom24">
          {
            tab === "signup" && isOpen ?
              <SignUpView onLogin={() => setTab("login")} onSuccess={() => onClose()} options={{email: defaultEmail}}/>
              :
              (
                tab == "login" && isOpen ?
                  <LoginView onSignUp={() => setTab("signup")} onSuccess={() => onClose()} options={{email: defaultEmail}}/>
                  :
                  null
              )
          }
        </div>
      </DialogContent>
    </Dialog>
  );
};
