import React, {useState, useEffect, useRef} from "react";
import {dataSourceV2} from "commons-ui/core/DataSource";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CreditCard from "@material-ui/icons/CreditCard";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WalletDialog from "./PaymentGateway/WalletDialog";
import Apple from "@material-ui/icons/Apple";
import ShopIcon from '@material-ui/icons/Shop';
import Contacts from "../Contacts";
import Delivery from "../Delivery";
import Payment from "./Payment";
import CartContent from "./CartContent";
import PaymentGateway from "./PaymentGateway";
import Checkbox from "@material-ui/core/Checkbox";

import "./style.styl";


const paymentList = [
    {name: "Credit Card", value: "gravity", icon: <CreditCard fontSize="large" className="u-textColorNormal"/>},
    {name: "Digital Wallets", description: "Apple Pay / Google Pay", value: "wallets", icon: <AccountBalanceWalletIcon fontSize="large" className="u-textColorNormal"/>},
]

export default function Checkout(props) {
    const {amount, values, errors, touched, disabled, onPayStart, onSubmit, cart, isPaymentSend, validateForm, isSubmitting, setSubmitting, onPaymentCancel, onPaymentSuccess, onSuccess, setFieldValue, ecommerceStoreId, isMobile, apiPath} = props;
    const [isPaymentOpen, setIsPaymentOpen] = useState(false);
    const [paymentProvider, setPaymentProvider] = useState("stripe");
    const [paymentSelected, setPaymentSelected] = useState("stripe");
    const [refundCheck, setRefundCheck] = useState(false);

    const ecommerceOrderIdRef = useRef();
    ecommerceOrderIdRef.current = values.ecommerce_order_id

    const isFree = values.total_amount < 0.03 && values.base_amount > 1 && values.ecommerce_store_gift_card_list.length > 0

    const onClose = () => {
        setIsPaymentOpen(false);
        props.onClose ? props.onClose() : null;
    };

    const onPay = () => {
        if (props.isV2) {
            onPayStart();
            setIsPaymentOpen(true);
        } else {
            props.onPay();
        }
    };

    const getPaymentProvider = () => {
        if (!ecommerceStoreId) {
            return;
        }
        dataSourceV2(`customer_payment_provider_get?ecommerce_store_id=${ecommerceStoreId}`, {url: apiPath})
            .then(v => {
                if (v.provider) {
                    setPaymentProvider(v.provider);
                }
            });
    };

    useEffect(() => {
        //getPaymentProvider();
    }, []);

    useEffect(() => {
        validateForm()
    }, [values, values.total_amount])
    return (
        <div>
            <span className="u-fontWeightBold u-fontSize18">Order</span>
            <div className="u-flexCenter u-marginTop10">
                <CartContent {...props} {...cart} deliveryType={values.delivery_type}/>
            </div>
            <div className="u-marginBottom25 u-marginTop40">
                <Delivery {...props}
                          readOnly
                          itemIdParent="ecommerce_store_id"
                          itemIdValueParent={ecommerceStoreId}
                          modelName="ecommerce_store_delivery_option"
                          itemId="ecommerce_store_delivery_option_id"/>
            </div>
            <div className="u-marginBottom10">
                <Contacts {...props} readOnly/>
            </div>
            <span className="u-fontWeightBold u-fontSize18 u-hide">Payment</span>
            <div className="">
                <Payment {...props}/>
            </div>

            {
                isFree ?
                  <div className="u-marginBottom100 u-marginTop40">
                      {
                          isSubmitting ?
                              <div className="u-flexColumn">
                                  <CircularProgress color="secondary" />
                                  <span className="u-marginTop10 u-textColorGreenDark">
                                      Please do not close the page
                                  </span>
                              </div>
                              :
                              <Button className="u-paddingHorizontal10" variant="contained" color="primary" size="large" onClick={() => validateForm().then(v => {
                                      if (v && Object.keys(v).length === 0) {
                                          setSubmitting(true)
                                          onSubmit().then(v => {
                                              if (ecommerceOrderIdRef.current) {
                                                  onPaymentSuccess({accountCardType: "GiftCard", amountProcessed: "0.00"})
                                              }
                                          });
                                      }
                                  })}>
                                  <CheckCircleIcon fontSize="small" className="u-marginRight8"/> Complete: <span className="u-fontWeightBold u-marginLeft10">$ 0.00</span>
                              </Button>
                      }
                  </div>
                  :
                  <div>
                      <span className="u-hide u-flexCenter u-fontWeightBold u-fontSize18 u-marginTop25 u-marginBottom25">Payment</span>
                        <div className="u-flexCenter">
                            <Checkbox
                                color="primary"
                                onChange={(event) => setRefundCheck(!refundCheck)}
                                checked={refundCheck}
                                className="u-padding5"
                            />
                            <span>By checking this box, you agree to our <a style={{color: "#336594"}} target="_blank" className="link" href="/policy-center/refund-policy">refund policy</a>.
                            </span>
                        </div>

                      <div className="u-flex u-marginBottom30 u-marginTop20 u-hide">
                          {
                            paymentList.map((item, i) => {
                                const isSelected = paymentSelected === item.value
                                return (
                                    <div key={i} onClick={() => setPaymentSelected(item.value)} className={"u-flexColumn u-textAlignCenter u-sizeFullHeight u-cursorPointer u-alignItemsCenter u-padding10 u-justifyContentCenter u-border1 u-borderRadius10 u-borderColorLighter u-marginRight15 " + (isSelected ? " " : "")} style={{minHeight: 110, backgroundColor: isSelected ? "#eee" : undefined}}>
                                        <span>{item.icon}</span>
                                        <span>{item.name}</span>
                                        {
                                          item.description ?
                                              <span className="u-marginTop5 u-textColorNormal u-fontSize8">{item.description}</span>
                                              :
                                              null
                                        }
                                    </div>
                                )
                            })
                          }
                      </div>

                          <div className="u-marginBottom100 u-marginTop20">
                              <div className="u-flexColumn" style={{maxWidth: "240px"}}>
                                  {
                                      isSubmitting || (!paymentProvider && props.isV2) ?
                                          <div className="u-flexColumn">
                                              <CircularProgress color="secondary" />
                                              <span className="u-marginTop10 u-textColorGreenDark">
                                                  Payment in progress. Please do not close the page
                                              </span>
                                          </div>
                                          :
                                          <Button disabled={!refundCheck || disabled} className="u-paddingHorizontal10" variant="contained" color="primary" size="large" onClick={() => validateForm().then(v => {
                                                  if (v && Object.keys(v).length === 0) {
                                                        if (values.total_amount === 0) {
                                                            
                                                            onSubmit().then(v => {
                                                                setSubmitting(true);
                                                                onPaymentSuccess()
                                                            });
                                                        } else {
                                                            onPay();
                                                            onSubmit().then(v => setSubmitting(true));
                                                        }
                                                      
                                                  }
                                              })}>
                                              <CreditCard fontSize="small" className="u-marginRight8"/> Pay: <span className="u-fontWeightBold u-marginLeft10">$ {(values.total_amount).toFixed(2)}</span>
                                          </Button>
                                  }
                                  {
                                    /*
                                        <div className="u-marginTop20 u-marginLeftNegative10 u-marginRightNegative10" style={{visibility: values.total_amount && Object.keys(errors).length === 0 ? undefined : "hidden"}}>
                                            <WalletDialog   {...props}
                                                            open={true}
                                                            isPaymentSend={isPaymentSend}
                                                            amount={values.total_amount}
                                                            onPrepay={() => {
                                                                return validateForm().then(v => {
                                                                    if (v && Object.keys(v).length === 0) {
                                                                        return onSubmit();
                                                                    } else {
                                                                        onPaymentCancel()
                                                                    }
                                                                })
                                                            }}
                                                            onSuccess={(options) => {
                                                                options = options || {};
                                                                if (options.with_unpaid) {
                                                                //onPay();
                                                                onSubmit().then(v => {
                                                                    onPaymentSuccess(options.data)
                                                                })
                                                                } else {
                                                                    onPaymentSuccess(options.data)
                                                                }
                                                            }}
                                                            onCancel={onPaymentCancel}
                                                            isMobile={isMobile}
                                                            noDialog
                                                            ecommerceOrderId={values.ecommerce_order_id}
                                                            ecommerceStoreId={ecommerceStoreId}
                                                            apiPath={apiPath}
                                                />
                                        </div> 
                                    */
                                  }

                              </div>

                              {
                                  Object.keys(errors).length > 0 ?
                                      <div className="u-flexColumn u-marginTop15 u-textColorRed u-fontSize10">
                                          {Object.values(errors).map((v, i) => {
                                              return (
                                                  <span>{v}</span>
                                              );
                                          })}
                                      </div>
                                      :
                                      null
                              }
                          </div>
                      {
                          paymentProvider && props.isV2 ?
                              <PaymentGateway {...props}
                                              open={isPaymentOpen}
                                              onClose={onClose}
                                              isMobile={isMobile}
                                              provider={paymentSelected || paymentProvider}
                                              amount={values.total_amount}
                                              paymentResultPolling={true}
                                              paymentDynamic={false}
                                              onSuccess={onPaymentSuccess}
                                              onCancel={onPaymentCancel}
                                              ecommerceStoreDeliveryOptionPickupId={values.ecommerce_store_delivery_option_pickup_id}
                                              ecommerceOrderId={values.ecommerce_order_id}
                                              apiPath={apiPath}/>
                                          :
                                          null
                      }
                  </div>
            }
        </div>
    );

}
