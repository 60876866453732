import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import CreditCard from "@material-ui/icons/CreditCard";
import Delete from "@material-ui/icons/Delete";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Add from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItem from "@material-ui/core/ListItem";
import { getDeliveryUpsaleText } from "ecommerce-commons-ui/core/utils";
import { prettyDateUSA } from "commons-ui/core/utils/date";
import CountWidget from "./CountWidget";
import { ExpressCheckout } from "../../ExpressCheckout"
import { calcPrice, renderNameVertical } from "ecommerce-commons-ui/core/utils";
import GiftCard from "ecommerce-commons-ui/core/Checkout/CheckoutStepForm/Checkout/Payment/GiftCard";
import Tips from "ecommerce-commons-ui/core/Checkout/CheckoutStepForm/Checkout/Payment/Tips";
import {defaultValues} from "ecommerce-commons-ui/core/Checkout/CheckoutStepForm"
import "./style.styl";
import { set } from "date-fns";

const mapping = {
    pick_up: "Store",
    delivery: "Delivery",
    event: "Event"
};

const getType = (item) => {
    if (!item) return "";
    if (item["ecommerce_store_delivery_option_pickup_id"]) {
        return "pick_up";
    } else if (item["ecommerce_store_delivery_option_rule_id"]) {
        return "delivery";
    } else if (item["ecommerce_store_delivery_option_event_id"]) {
        return "event";
    }
    return "";
};

export default function CartContent(props) {
    const { items, type, deliveryItem, login, recaptchaKey, location, isMobile, ecommerceStoreId, ecommerceStoreGroupId, isOpen, onClose, onContinue, apiPath, mediaPath, noImage, noWatermark, dispatch } = props;
    const withPayment = ["event", "pick_up", "pickup", "delivery", "shipping"].includes(deliveryItem.delivery_type)
    const [loading, setLoading] = useState(withPayment ? true : false);
    const [values, setValues] = useState(defaultValues());
    const [paymentAvailable, setPaymentAvailable] = useState(true);
    const [formActionOpen, setFormActionOpen] = useState("");
    const total = items.reduce((a, b) => a + (calcPrice(b.product || {}, b.featureList).final_price || 0) * (b.count || 0), 0).toFixed(2);
    const isCartEmpty = !items.length;


    const setFieldValue = (field, value) => {
        setValues({
            ...values,
            [field]: value
        });
    };

    const onDeleteGiftCard = (number) => {
        const cards = [...values.ecommerce_store_gift_card_list].filter(v => v.number !== number)
        setFieldValue("ecommerce_store_gift_card_list", cards)
    }


    const onDeletePromocode = () => {
      setValues({
        ...values,
        ecommerce_store_promocode_discount_percent: 0,
        ecommerce_store_promocode_discount: 0,
        ecommerce_store_promocode: {},
        ecommerce_store_promocode_code: "",
        promocode: ""
      })
    }

    const getEarnPoints = () => {
        const points = values.base_amount * 10
        return Math.floor(points)
    }

    let baseAmountInitial = values.base_amount
    items.forEach((item, i) => {
        if (!item) {
            return null
        }
        if (!item.free) {
            return null
        }
        const discount = (item.free * (item.price || 0))
        if (discount) {
            baseAmountInitial += discount
        }
    })

    console.log("CART:", items)

    const hasErrors = Object.values(values.errors || {}).length > 0

    return (
        <div className={"u-sizeFullHeight " + (isMobile ? "" : "u-flexColumn")}>
            <div className="cart-header u-flexCenter u-justifyContentSpaceBetween u-paddingHorizontal15">
                <div className={"u-flexColumn " + (isMobile ? "u-paddingVertical5" : "u-paddingVertical15")}>
                    {
                        withPayment ?
                            <div className="u-flexCenter">
                                {
                                    Object.keys(deliveryItem || {}).length ?
                                        <span className="u-fontSize10">
                                            <span>{mapping[getType(deliveryItem)] || "Delivery"}: {(deliveryItem || {}).name}</span>
                                            {
                                                deliveryItem.delivery_requested_date ?
                                                    <span> - {prettyDateUSA(new Date(deliveryItem.delivery_requested_date))}</span>
                                                    :
                                                    null
                                            }
                                            {
                                                deliveryItem.delivery_requested_time ?
                                                    <span className="u-marginLeft5">{deliveryItem.delivery_requested_time.replace("_", "-")}</span>
                                                    :
                                                    null
                                            }
                                        </span>
                                        :
                                        null
                                }
                            </div>
                            :
                            <DialogTitle className="u-padding0">Cart</DialogTitle>
                    }
                    
                    
                </div>
                <IconButton className="u-marginRightNegative5 u-padding5" color="inherit" onClick={onClose}>
                    <Close />
                </IconButton>
            </div>
            <List className="cart-content u-paddingVertical10 u-borderTop1 u-borderColorLightest u-minHeight250">
                {
                    isCartEmpty ?
                        <div className="u-sizeFullHeight u-flexColumn u-justifyContentCenter u-alignItemsCenter">
                            <div className="u-paddingHorizontal50 u-paddingBottom20">
                                <img className="u-sizeFullWidth u-hide" src="/static/img/empty_cart_2.png" />
                            </div>
                            <div className="u-textColorNormal u-textAlignCenter u-marginTop50">
                                Empty Cart
                            </div>
                        </div>
                        :
                        null
                }
                {
                    items.map((item, i) => {

                        const product = item.product || {};
                        const featureDict = item.featureDict || {};
                        const featureList = item.featureList || [];
                        const { price_fixed, discount_fixed, discount_percent } = calcPrice(product, featureList);
                        const price = discount_percent ? discount_fixed : price_fixed;
                        const path = product.images ? mediaPath + (product.images || "").split(",")[0] : (noImage || "https://assets.smallcase.com/images/smallcases/200/SCSB_0004.png");
                        const noBorder = (i === items.length - 1) ? " u-border0 " : " u-borderBottom1 u-borderColorLightest ";
                        const cartLimit = parseInt(product.cart_limit || "0") || 0
                        return (
                            <ListItem key={i} className={"u-paddingVertical0 u-paddingRight0 " + noBorder}>
                                <div className="u-flexCenter u-sizeFullWidth u-paddingRight15">
                                    <div className="u-relative u-inlineBlock u-marginVertical4 u-verticalAlignTop u-marginRight15">
                                        <div className={"image-widget-item u-relative"} 
                                            style={{ width: 60, height: 50, backgroundImage: `url(${path})`, backgroundSize: "cover" }}></div>
                                    </div>
                                    <div className="u-sizeFullWidth u-flexColumn">
                                        <div className="name u-fontSize12 u-textColorNormal u-marginBottom5 u-xs-fontSize12">{renderNameVertical(product, { featureList, features: [] })}</div>
                                        <div className="u-flexCenter u-justifyContentSpaceBetween u-xs-fontSize16">
                                            <div className={discount_percent ? "u-textColorRed" : ""}>$ {price}</div>
                                            {
                                                deliveryItem && deliveryItem.delivery_type === "shipping_event" ?
                                                    <div className="u-flexCenter u-marginRight15">
                                                        <span>Qty: {item.count || 0}</span>
                                                    </div>
                                                    :
                                                    <div className="u-flexCenter">
                                                        <CountWidget itemIdValue={item.id} value={item.count || 0} dispatch={dispatch} limit={cartLimit} />
                                                        <div className="u-flexCenter u-marginLeft5">
                                                            <IconButton className="u-padding2 u-opacity80" onClick={() => dispatch("cart", { id: item.id }, "remove_product")}>
                                                                <Delete className="u-fontSize15" />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </ListItem>
                        );
                    })
                }
            </List>
            {
                !isCartEmpty ?
                    <div className="cart-actions u-borderTop1 u-borderColorLightest u-relative">
                        {loading && (
                            <div className="cart-actions-loading-overlay">
                                <div className="spinner"></div>
                            </div>
                        )}
                        <div className="u-paddingLeft15 u-flexColumn u-justifyContentSpaceBetween u-xs-fontSize16 u-xs-flexColumn u-paddingRight15 u-paddingVertical15">

                            {
                                values && values.total_amount ?
                                    <div className="u-flexCenter u-justifyContentCenter u-sizeFullWidth">
                                        <div className=" u-flexColumn u-sizeFullWidth u-maxWidth200 u-xs-maxWidthNone u-borderColorLighter">
                                            <span className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                                                <span>Order:</span>
                                                <span>${baseAmountInitial.toFixed(2)}</span>
                                            </span>

                                            {
                                                items.map((item, i) => {
                                 
                                                    if (!item) {
                                                        return null
                                                    }
                                                    if (!item.free) {
                                                        return null
                                                    }
                                                    const discount = (item.free * (item.price || 0)).toFixed(2)

                                                    return (
                                                        <span key={i} className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4 u-fontSize11 ">
                                                            <span className="u-fontSize9" style={{color: "rgb(27, 95, 6)"}}>{item.product.name}</span>
                                                            <span style={{color: "rgb(27, 95, 6)"}}>-${discount}</span>
                                                        </span>
                                                    )
                                                })
                                            }

                                            {
                                                values.promocode_amount ?
                                                    <span className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                                                        <span>Promocode:</span>
                                                        <span className="u-flexCenter">
                                                            <span onClick={() => onDeletePromocode()} className="link u-marginRight10 u-fontSize8" style={{ color: "#4050b5" }}>remove</span>
                                                            <span className="u-textColorGreenDark">-${Math.abs(values.promocode_amount || 0).toFixed(2)}</span>
                                                        </span>
                                                    </span>
                                                    :
                                                    null
                                            }
                                            {
                                                values.labeling_amount ?
                                                    <span className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                                                        <span>Labeling:</span>
                                                        <span className="u-flexCenter">
                                                            <span onClick={() => setValues({...values, labeling_amount: 0})} className="link u-marginRight10 u-fontSize8" style={{ color: "#4050b5" }}>remove</span>
                                                            <span className="">${values.labeling_amount.toFixed(2)}</span>
                                                        </span>
                                                    </span>
                                                    :
                                                    null
                                            }
                                            {
                                                values.delivery_amount ?
                                                    <span className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                                                        <span>{["event", "pickup", "pick_up"].includes(values.delivery_type) ? "Handling Fee" : (values.delivery_type === "shipping" ? "Shipping Cost" : "Delivery")}:</span>
                                                        <span>${values.delivery_amount.toFixed(2)}</span>
                                                    </span>
                                                    :
                                                    null
                                            }

                                            {
                                                values.packaging_amount ?
                                                    <span className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                                                        <span>Packaging:</span>
                                                        <span>${values.packaging_amount.toFixed(2)}</span>
                                                    </span>
                                                    :
                                                    null
                                            }
                                            {
                                                values.tips_amount ?
                                                    <span className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                                                        <span>Tips:</span>
                                                        <span className="u-flexCenter">
                                                            <span onClick={() => setValues({...values, tips_amount: 0, tips_amount_custom: 0, tips_amount_percent: 0})} className="link u-marginRight10 u-fontSize8" style={{ color: "#4050b5" }}>remove</span>
                                                            <span className="">${values.tips_amount.toFixed(2)}</span>
                                                        </span>
                                                    </span>
                                                    :
                                                    null
                                            }

                                            {
                                                values.tax_amount ?
                                                    <span className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                                                        <span>Tax:</span>
                                                        <span>${values.tax_amount.toFixed(2)}</span>
                                                    </span>
                                                    :
                                                    null
                                            }
                                            {
                                                values.ecommerce_store_gift_card_list.map((card, i) => {
                                                    if (!card.amount) {
                                                        return null
                                                    }
                                                    return (
                                                        <span key={i} className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                                                            <span className="u-textColorGreenDark u-textColorGreenNormal">{card.name || ("Gift Card #" + (i + 1).toString())}:</span>
                                                            <span className="u-flexCenter">
                                                                <span onClick={() => onDeleteGiftCard(card.number)} className="link u-marginRight10 u-fontSize8" style={{color: "#4050b5"}}>remove</span>
                                                                <span className="u-fontWeightBold" style={{color: "rgb(27, 95, 6)"}}>-${card.amount.toFixed(2)}</span>
                                                            </span>
                                                        </span>
                                                    )
                                                })
                                            }

                                            

                                            

                                            <span className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                                                <span>Total:</span>
                                                <span className="u-fontWeightBold">${values.total_amount.toFixed(2)}</span>
                                            </span>

                                        </div>
                                    </div>
                                    :
                                    <div className="u-flexCenter">
                                        <div>Total:</div>
                                        <div className="u-marginLeft10">$ {total}</div>
                                    </div>
                            }

                            {
                                Object.values(values.errors || {}).map((error, i) => {
                                    return (
                                        <span className="u-fontSize10 u-textColorRed u-marginTop5 u-flexCenter u-justifyContentCenter" key={i}>{error}</span>
                                    )
                                })
                            }

                                            {
                                                login.token ?
                                                    <span className="u-flex u-textColorDarkGreen u-flexCenter u-justifyContentCenter u-marginTop10 u-fontSize12">
                                                        You will earn <span className="u-fontWeightBold u-paddingHorizontal5" style={{color: "green"}}>{getEarnPoints()}</span> loyalty points from this order
                                                    </span>
                                                    :
                                                    null
                                            }
                                            {
                                                values.is_frozen ?
                                                    <span className="u-flex u-marginTop10 u-fontSize12 u-flexCenter u-justifyContentCenter">
                                                        Your order will be <b className="u-marginLeft5" style={{color: "#6565ec"}}>FROZEN</b>
                                                    </span>
                                                    :
                                                    null
                                            }

                            <span className="u-fontSize10 u-textColorNormal u-xs-marginTop5">
                                {getDeliveryUpsaleText((deliveryItem || {}).delivery_cost_json, parseFloat(total))}
                            </span>

                            {
                                (withPayment && paymentAvailable) ?
                                    <div className="u-sizeFullWidth u-marginTop5 u-flexCenter u-justifyContentCenter u-fontSize10 u-flexWrap">

                                        <span className="link u-flexCenter" onClick={() => setFormActionOpen("tips") }>
                                            add tips
                                        </span>

                                        <span className="link u-flexCenter u-marginLeft10" onClick={() => {
                                            if (!values.promocode_amount) {
                                                setFormActionOpen("promocode")
                                            }
                                        } }>
                                            use promocode
                                        </span>

                                        <span className="link u-flexCenter u-marginLeft10" onClick={() => setFormActionOpen("loyalty") }>
                                            use loyalty
                                        </span>

                                        <span className="link u-flexCenter u-marginLeft10" onClick={() => setFormActionOpen("gift") }>
                                            use gift card
                                        </span>

                                        <span className="link u-flexCenter u-marginLeft10" onClick={() => {
                                            if (!values.labeling_amount) {
                                                setFormActionOpen("labeling")
                                            } 
                                        }}>
                                            add labeling
                                        </span>
                                    </div>
                                    :
                                    null
                            }

                            
                            <CartActionDialog {...props} getEarnPoints={getEarnPoints} onAction={(action) => {
                                    if (action === "gift_card_applied" || action === "tips_changed") {
                                        setFormActionOpen("")
                                    }
                                }} 
                                setFieldValue={setFieldValue} setValues={setValues} values={values} open={formActionOpen} onClose={() => setFormActionOpen("")} mode={formActionOpen} />
                        </div>
                        
                        {
                            false ?
                                <div></div>
                                :
                                (
                                    (withPayment && (paymentAvailable || true)) ?
                                        <div>
                                            <div className="u-flexColumn u-alignItemsCenter" style={{visibility: hasErrors ? "hidden" : "visible"}}>

                                                <div className={"u-flexCenter u-xs-flexColumn u-paddingHorizontal15 " + (!paymentAvailable ? "u-flexColumn" : "")} style={{ visibilitys1: loading ? "hidden" : "visible" }}>
                                                    <ExpressCheckout onInit={(res) => {
                                                        if (res.ok) {
                                                            setPaymentAvailable(true)
                                                            window.dataLayer && window.dataLayer.push({ event: 'begin_checkout' });
                                                        } else {
                                                            setPaymentAvailable(false)
                                                        }
                                                        console.log("res", res)
                                                        setLoading(false)
                                                    }}
                                                        onValidateData={(data) => {
                                                            setValues({...values, ...(data || {})});
                                                            if (formActionOpen && formActionOpen !== "loyalty") {
                                                                console.log("validate:", data)
                                                                let noErrors = true;
                                                                if (Object.keys(data.errors || {}).length > 0) {
                                                                    noErrors = false
                                                                }
                                                                Object.keys(data).forEach((key) => {
                                                                    if (key.includes("error") && key !== "errors" && data[key]) {
                                                                        noErrors = false
                                                                    }
                                                                });
                                                                console.log("RTTOTS:", noErrors, formActionOpen, data)
                                                                if (noErrors) {
                                                                    setFormActionOpen("")   
                                                                }
                                                            }
                                                        }}
                                                        onSuccess={(order = {}) => {
                                                            window.dataLayer && window.dataLayer.push({
                                                                event: 'placed_order',
                                                                ecommerce: { value: order.total_amount || 10, currency: 'USD' }
                                                            });
                                                        }}
                                                        loading={loading}
                                                        setLoading={setLoading}
                                                        products={items}
                                                        values={values}
                                                        ecommerceStoreId={ecommerceStoreId}
                                                        rule={deliveryItem}
                                                        deliveryRequestedDate={(deliveryItem || {}).delivery_requested_date}
                                                        deliveryRequestedTime={(deliveryItem || {}).delivery_requested_time}
                                                        dispatch={dispatch}
                                                        deliveryOption={deliveryItem}
                                                        ecommerceStoreGroupId={ecommerceStoreGroupId}
                                                        location={location}
                                                        isMobile={isMobile}
                                                        noLoading
                                                        gift={{ is_gift: false, gift_sender_name: "", gift_recipient_name: "", gift_message: "" }}
                                                        recaptchaKey={recaptchaKey}
                                                        apiPath={apiPath} />

                                                    <div
                                                        style={{
                                                            padding: '5px 20px',
                                                            backgroundColor: '#555555', // Dark gray
                                                            color: '#fff',
                                                            width: "300px",
                                                            borderRadius: '5px',
                                                            textAlign: 'center',
                                                            cursor: 'pointer',
                                                            maxWidth: '292px',
                                                            height: '44px',
                                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                            transition: 'background-color 0.3s',
                                                        }}
                                                        className="u-flexCenter u-justifyContentCenter u-marginLeft15 u-xs-marginLeft0 u-xs-marginTop15"
                                                        onClick={() => { onClose(); dispatch("/checkout"); }}
                                                        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#333333')}
                                                        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#555555')}
                                                    >
                                                        <CreditCard className="u-marginRight5" fontSize="small" /> {paymentAvailable ? "Credit Card" : "Checkout"}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="u-paddingVertical20 u-flexCenter u-justifyContentCenter">or <span className="link u-marginLeft5" onClick={() => { (onContinue ? onContinue() : null); onClose(); }}>Continue Shopping</span></div>
                                        </div>
                                        :
                                        <div style={{visibility: hasErrors ? "hidden" : "visible"}} className="u-flexCenter u-xs-flexColumn u-justifyContentCenter u-paddingBottom20 u-paddingHorizontal15">
                                            {
                                                deliveryItem && deliveryItem.delivery_type === "shipping_event" ?
                                                    null
                                                    :
                                                    <Button size="small" className="button-grey u-height48 u-marginRight10 u-xs-marginRight0 u-xs-sizeFullWidth"
                                                        onClick={() => { (onContinue ? onContinue() : null); onClose(); }}>
                                                        Continue Shopping
                                                    </Button>
                                            }


                                            <Button className="button-secondary u-height48 u-marginLeft10 u-xs-marginLeft0 u-xs-sizeFullWidth u-xs-marginTop15"
                                                link-meta="/checkout"
                                                onClick={() => { onClose(); dispatch("/checkout"); }}>
                                                Checkout <ChevronRight />
                                            </Button>
                                        </div>
                                )
                        }

                    </div>
                    :
                    null
            }
        </div>
    );
}


export const CartActionDialog = (props) => {
    const { open, values, setValues, onAction, getEarnPoints, onClose } = props;



    return (
      <Dialog
        fullScreen={false}
        onClose={onClose}
        open={open}
        maxWidth="xs"
        fullWidth
        className=""
        scroll="paper"
      >
        <div className={"u-relative u-paddingHorizontal20 u-paddingTop5 u-paddingBottom25 "}>
            <IconButton size="small" className="u-absolute u-top10 u-right15" onClick={onClose}>
                <Close/>
            </IconButton>
            {
                open === "promocode" ?
                    <GiftCard {...props} getEarnPoints={getEarnPoints} mode="promocode"/>
                    :
                    null
            }
            {
                open === "loyalty" ?
                    <GiftCard {...props} getEarnPoints={getEarnPoints} mode="loyalty" noFree/>
                    :
                    null
            }
            {
                open === "gift" ?
                    <GiftCard {...props} getEarnPoints={getEarnPoints} mode="gift"/>
                    :
                    null
            }
            {
                open === "tips" ?
                    <div>
                        <div className="u-flexCenter u-marginTop20 u-marginBottom15 u-fontSize18">Add a Tip:</div>
                        <Tips {...props}/>
                        <div className="u-flexCenter u-justifyContentCenter u-marginTop10 u-fontSize10 u-opacity70">Creating and getting our Piroshky to you is a multilayered process that depends on the talent and contribution of various individuals. When you choose to tip, your gratuity is distributed evenly between all of our crew, from cleaners and prep team, to bakers and drivers. Our team thanks you for your generosity and support.</div>
                    </div>
                    :
                    null
            }
            {
                open === "labeling" ?   
                    <div>
                        <div className="u-flexCenter u-justifyContentCenter u-marginTop20">We individually label each piroshky so you can easily tell them apart, making your order more convenient to enjoy.</div>
                        <div className="u-flexCenter u-justifyContentCenter u-marginTop15">
                            <Button size="small" className="button-secondary u-xs-marginRight0 u-xs-sizeFullWidth"
                                                            onClick={() => {
                                                                onClose();
                                                                setValues({ ...values, labeling_amount: 4 });
                                                            }}>
                                    Add Labeling: $4
                            </Button>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
      </Dialog>
    );
  
  }