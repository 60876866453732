import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import "./style.styl";

const tipsData = [
    {name: "5%", value: 5},
    {name: "10%", value: 10},
    {name: "15%", value: 15},
    {name: "20%", value: 20},
    {name: "25%", value: 25}
];

export default function Tips(props) {
    const {errors={}, values={}, setValues, onAction, touched} = props
    const [tipsCustom, setTipsCustom] = useState(0);

    return (
        <div className="u-marginTop5 u-flexCenter u-flexWrap">
                {
                    tipsData.map((v, i) => {
                        const isSelected = values.tips_amount_percent === v.value;
                        return (
                            <Chip
                                onClick={() => {
                                    setValues({ ...values, "tips_amount_percent": isSelected ? 0 : v.value, tips_amount_custom: 0 })
                                    setTipsCustom(0)
                                    if (onAction) {
                                        onAction("tips_changed")
                                    }
                                }}
                                label={v.name}
                                className={"tip-button u-marginRight10 u-marginBottom5 u-height40 u-paddingHorizontal10 u-borderRadius50 " + (isSelected ? "selected" : "")}
                              />
                        );
                    })
                }
                <TextField
                    error={errors.tips_amount_custom}
                    margin="dense"
                    type="number"
                    placeholder="$0"
                    helperText={errors.tips_amount_custom ? errors.tips_amount_custom : ""}
                    className="u-sizeFullWidth u-maxWidth80 u-marginBottom12 u-textAlignCenter"
                    onChange={(e) => setTipsCustom(parseFloat(e.target.value) || 0)}
                    onBlur={(e) => {
                        !e.target.value || e.target.value.toString().length <= 4 ? setValues({...values, "tips_amount_custom": e.target.value ? (parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : 0) : 0, "tips_amount_percent": 0}) : null;
                        if (onAction) {
                            onAction("tips_changed")
                        }
                    }}
                    value={parseFloat(tipsCustom ? tipsCustom.toFixed(2) : null)}
                    variant="outlined"
                />
            </div>
    )

}
