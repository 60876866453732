import React, {useState, useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import {Form} from "formik";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutline from "@material-ui/icons/HelpOutline";
import {dataSourceV2} from "commons-ui/core/DataSource";
import MuiSelect from "commons-ui/core/MuiSelect";
import renderHTML from "react-render-html";
import MaskedInput from "react-text-mask";
import {KeyboardDatePicker, DatePicker} from "@material-ui/pickers";
import {getRequestedTime, getMinDate} from "../utils";
import {dateToIsoTZ} from "commons-ui/core/utils/date";
import "./style.styl";


function PhoneMask(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => {inputRef(ref ? ref.inputElement : null);}}
            mask={["(", /[2-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={"_"}
        />
    );
}


export default function DeliveryForm(props) {
    const {values, cart, item, errors, touched, onBack, itemId, itemIdValue, setFieldValue, readOnly, readOnlySelect, isSubmitting, apiPath} = props;
    const [cost, setCost] = useState(0);
    const [rule, setRule] = useState({});
    const [loading, setLoading] = useState(false);


    const request = () => {
        setLoading(true);
        let dateIso = dateToIsoTZ(values.delivery_requested_date  ? new Date(values.delivery_requested_date) : new Date()).replace("+", "p");
        let dateNow = dateToIsoTZ(new Date()).replace("+", "p");
        dataSourceV2(`ecommerce_store_delivery_cost?index=${values.delivery_postal_code}&${itemId}=${itemIdValue}&date=${dateIso}&date_now=${dateNow}&base_amount=${(values.base_amount || 0).toString()}`, {url: apiPath})
            .then(v=> {
                if (!v.message && v.item) {
                    setCost(v.item.cost || 0);
                    setRule(v.item.rule || {});
                }
                setLoading(false);
            });
    };

    useEffect(() => {
        if (itemIdValue) request();
    }, [itemIdValue, cart.items]);

    useEffect(() => {
        request();
    }, [values.delivery_postal_code, values.delivery_requested_date]);

    useEffect(() => {
        return
        setFieldValue("min_order", rule.min_order || item.min_order || 0);
    }, [item.min_order, rule.min_order]);

    useEffect(() => {
        setFieldValue("delivery_state", "WA");
    }, []);

    useEffect(() => {
        return
        setFieldValue("packaging_amount", rule.packaging_cost || item.packaging_cost || 0);
    }, [item.packaging_cost, rule.packaging_cost]);

    useEffect(() => {
        return
        setFieldValue("tax_rate_global", rule.tax_rate_percent || item.tax_rate_percent || 0);
    }, [item.tax_rate_percent, rule.tax_rate_percent]);

    useEffect(() => {
        return
        if (rule.has_labeling_cost && rule.labeling_cost) setFieldValue("labeling_cost", rule.labeling_cost || 0);
    }, [rule.has_labeling_cost, rule.labeling_cost]);

    useEffect(() => {
        console.log("thisss1", rule)
        setFieldValue("ecommerce_store_delivery_option_rule", rule);
    }, [Object.keys(rule).length]);


    const disableDays = (date) => {
        try {
            const days = (item.requested_date_disabled_days_of_week || "").split(";");
            for (let i = 0; i < days.length; i++ ) {

                let convertedDay = parseInt(days[i]) + 1;
                if (convertedDay === 7){
                    convertedDay = 0;
                }
                if (date.getDay() === convertedDay ){
                    return true;
                }
            }

            if (item.requested_date_disabled_start_at && item.requested_date_disabled_end_at) {
                const startAt = new Date(item.requested_date_disabled_start_at);
                startAt.setHours(0);
                startAt.setMinutes(0);
                const endAt = new Date(item.requested_date_disabled_end_at);
                endAt.setHours(23);
                endAt.setMinutes(59);
                if (date.getTime() >= startAt.getTime() && date.getTime() <= endAt.getTime()) {
                    return true;
                }
            }
        } catch {
            console.log("disable date error");
        }

        return false;
      };


    const shift = rule.delivery_requested_date_shift_minutes || item.delivery_requested_date_shift_minutes;

    return (
        <Form>
            <div className="u-flex u-justifyContentSpaceBetween u-xs-flexColumn">
                <div className="u-maxWidth400 u-sizeFullWidth">
                    <TextField
                        error={errors.delivery_name && touched.delivery_name}
                        label="Deliver To First Name *"
                        margin="dense"
                        disabled={readOnly}
                        helperText={errors.delivery_name && touched.delivery_name ? errors.delivery_name : ""}
                        className="u-sizeFullWidth u-marginVertical12"
                        onChange={(e) => setFieldValue("delivery_name", e.target.value)}
                        value={values.delivery_name}
                        variant="outlined"
                    />
                    <TextField
                        error={errors.delivery_surname && touched.delivery_surname}
                        helperText={errors.delivery_surname && touched.delivery_surname ? errors.delivery_surname : ""}
                        label="Last Name *"
                        margin="dense"
                        disabled={readOnly}
                        className="u-sizeFullWidth u-marginVertical12"
                        onChange={(e) => setFieldValue("delivery_surname", e.target.value)}
                        variant="outlined"
                        value={values.delivery_surname}
                    />
                    <TextField
                        error={errors.delivery_phone && touched.delivery_phone}
                        helperText={errors.delivery_phone && touched.delivery_phone ? errors.delivery_phone : "By providing your phone number, you agree that we may text you with reminders and other info. Reply STOP to opt out of text messaging.  Standard rates apply."}
                        label="Delivery Phone *"
                        margin="dense"
                        disabled={readOnly}
                        className="u-sizeFullWidth u-marginVertical12"
                        onChange={(e) => setFieldValue("delivery_phone", e.target.value)}
                        variant="outlined"
                        value={values.delivery_phone}
                        InputProps={{inputComponent: PhoneMask}}
                    />

                    <TextField
                        error={errors.delivery_address && touched.delivery_address}
                        label="Address Line 1 *"
                        margin="dense"
                        disabled={readOnly}
                        helperText={errors.delivery_address && touched.delivery_address ? errors.delivery_address : ""}
                        className="u-sizeFullWidth u-marginVertical12"
                        onChange={(e) => setFieldValue("delivery_address", e.target.value)}
                        value={values.delivery_address}
                        variant="outlined"
                    />
                    <TextField
                        error={errors.delivery_address2 && touched.delivery_address2}
                        label="Apt Number (if applicable)"
                        margin="dense"
                        disabled={readOnly}
                        helperText={errors.delivery_address2 && touched.delivery_address2 ? errors.delivery_address2 : ""}
                        className="u-sizeFullWidth u-marginVertical12"
                        onChange={(e) => setFieldValue("delivery_address2", e.target.value)}
                        value={values.delivery_address2}
                        variant="outlined"
                    />
                    <TextField
                        error={errors.delivery_city && touched.delivery_city}
                        label="City *"
                        margin="dense"
                        disabled={readOnly}
                        helperText={errors.delivery_city && touched.delivery_city ? errors.delivery_city : ""}
                        className="u-sizeFullWidth u-marginVertical12"
                        onChange={(e) => setFieldValue("delivery_city", e.target.value)}
                        value={values.delivery_city}
                        variant="outlined"
                    />

                <MuiSelect  items={stateItems}
                                selected={values.delivery_state}
                                htmlFor={"state_select"}
                                label="State *"
                                margin="dense"
                                disabled={true}
                                className="u-marginVertical12 u-sizeFullWidth"
                                onSelect={(type) => setFieldValue("delivery_state", type)}
                    />

                    <TextField
                        error={errors.delivery_postal_code && touched.delivery_postal_code}
                        label="Your Postal Code *"
                        margin="dense"
                        inputProps={{ maxLength: 5 }}
                        disabled={readOnly || readOnlySelect}
                        helperText={errors.delivery_postal_code && touched.delivery_postal_code ? errors.delivery_postal_code : ""}
                        className="u-sizeFullWidth u-marginVertical12"
                        onChange={(e) => setFieldValue("delivery_postal_code", e.target.value)}
                        value={values.delivery_postal_code}
                        variant="outlined"
                    />



                    <div className="u-flexCenter u-xs-flexColumn">
                        <DatePicker
                          disableToolbar
                          disablePast
                          variant="inline"
                          className="u-sizeFullWidth u-marginVertical12"
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          margin="dense"
                          shouldDisableDate={disableDays}
                          disabled={readOnly || readOnlySelect}
                          minDate={getMinDate(shift, item.delivery_requested_time_options, item.delivery_requested_time_options_interval)}
                          id="date-picker-inline"
                          label="Requested Delivery Date"
                          error={errors.delivery_requested_date && touched.delivery_requested_date}
                          helperText={(errors.delivery_requested_date && touched.delivery_requested_date) ? errors.delivery_requested_date : undefined}
                          value={values.delivery_requested_date ? new Date(values.delivery_requested_date) : null}
                          onChange={(date) => {
                              if (disableDays(date)) {
                                  return
                              }
                              !date || isNaN(date.getTime()) ? null : setFieldValue("delivery_requested_date", date.toISOString());
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                    </div>

                    {
                        values.delivery_requested_date && !item.is_requested_time_disabled && !rule.is_requested_time_disabled ?
                            <MuiSelect              items={getRequestedTime(item.delivery_requested_time_options, new Date(values.delivery_requested_date), shift, item.delivery_requested_time_options_interval)}
                                                    selected={values.delivery_requested_time}
                                                    htmlFor={"delivery_requested_time_options_id"}
                                                    className="u-minWidth240 u-sizeFullWidth u-marginVertical12"
                                                    label="Requested Delivery Time"
                                                    error={errors.delivery_requested_time && touched.delivery_requested_time}
                                                    disabled={readOnly}
                                                    onSelect={(value) => setFieldValue("delivery_requested_time", value)}
                            />
                            :
                            null
                    }

                    <TextField
                        label="Comments (Special Instructions)"
                        className="u-sizeFullWidth u-marginVertical12 u-hide"
                        margin="dense"
                        multiline
                        rows={2}
                        disabled={readOnly}
                        error={errors.customer_note && touched.customer_note}
                        helperText={errors.customer_note && touched.customer_note ? errors.customer_note : ""}
                        onChange={(e) => setFieldValue("customer_note", e.target.value)}
                        value={values.customer_note}
                        variant="outlined"
                    />

                    <div className="u-marginTop8 u-fontSize14 u-sizeFullWidth">
                        Delivery Cost: {loading ? "loading" : ("$" + cost.toFixed(2))}
                    </div>

                    {
                        cost && item.delivery_cost_explanation ?
                            <div className="u-fontSize10 u-marginTop5 u-textColorNormal">
                                {item.delivery_cost_explanation}
                            </div>
                            :
                            null
                    }

                    {
                        rule.packaging_cost || item.packaging_cost ?
                            <div className="u-marginTop8 u-fontSize14 u-sizeFullWidth">
                                Packaging Cost: {"$" + (parseFloat(rule.packaging_cost || item.packaging_cost) || 0).toFixed(2)}
                            </div>
                            :
                            null
                    }

                    {
                        rule.min_order || item.min_order ?
                            <div className="u-flexColumn u-marginTop8 u-fontSize14 u-sizeFullWidth">
                                <span className="u-flexCenter">
                                    Min. Order: {"$" + (parseFloat(rule.min_order || item.min_order) || 0).toFixed(2)}
                                    <Tooltip title={"Min. order of in-house items before tax"}>
                                        <HelpOutline fontSize="small" className="u-textColorNormal u-marginLeft5"/>
                                    </Tooltip>
                                </span>

                                <span>{errors.min_order && touched.min_order ? <div className="MuiFormHelperText-root Mui-error">{errors.min_order}</div> : ""}</span>
                            </div>
                            :
                            null

                    }

                    {
                        rule.max_order || item.max_order ?
                            <div className="u-marginTop8 u-fontSize14 u-sizeFullWidth">
                                Max. Order: {(parseFloat(rule.max_order || item.max_order) || 0).toFixed(2) + "$"}
                            </div>
                            :
                            null
                    }


                </div>
                <div className="u-maxWidth400 u-sizeFullWidth">
                    <div className="u-textColorNormal u-marginTop8 u-xs-marginTop25 u-fontSize13">
                        {item.description && renderHTML(item.description)}
                    </div>
                </div>
            </div>
        </Form>
    );
}



export const stateItems = [
    {value: "AK", name: "Alaska", lat: "63.588753", lon: "-154.493062"},
    {value: "AL", name: "Alabama", lat: "32.318231", lon: "-86.902298"},
    {value: "AR", name: "Arkansas", lat: "35.20105", lon: "-91.831833"},
    {value: "AZ", name: "Arizona", lat: "34.048928", lon: "-111.093731"},
    {value: "CA", name: "California", lat: "36.778261", lon: "-119.417932"},
    {value: "CO", name: "Colorado", lat: "39.5501", lon: "-105.7821"},
    {value: "CT", name: "Connecticut", lat: "41.603221", lon: "-73.087749"},
    {value: "DC", name: "District Of Columbia", lat: "38.905985", lon: "-77.033418"},
    {value: "DE", name: "Delaware", lat: "38.910832", lon: "-75.52767"},
    {value: "FL", name: "Florida", lat: "27.664827", lon: "-81.515754"},
    {value: "GA", name: "Georgia", lat: "32.157435", lon: "-82.907123"},
    {value: "HI", name: "Hawaii", lat: "19.898682", lon: "-155.665857"},
    {value: "IA", name: "Iowa", lat: "41.878003", lon: "-93.097702"},
    {value: "ID", name: "Idaho", lat: "44.068202", lon: "-114.742041"},
    {value: "IL", name: "Illinois", lat: "40.633125", lon: "-89.398528"},
    {value: "IN", name: "Indiana", lat: "40.551217", lon: "-85.602364"},
    {value: "KS", name: "Kansas", lat: "39.011902", lon: "-98.484246"},
    {value: "KY", name: "Kentucky", lat: "37.839333", lon: "-84.270018"},
    {value: "LA", name: "Louisiana", lat: "31.244823", lon: "-92.145024"},
    {value: "MA", name: "Massachusetts", lat: "42.407211", lon: "-71.382437"},
    {value: "MD", name: "Maryland", lat: "39.045755", lon: "-76.641271"},
    {value: "ME", name: "Maine", lat: "45.253783", lon: "-69.445469"},
    {value: "MI", name: "Michigan", lat: "44.314844", lon: "-85.602364"},
    {value: "MN", name: "Minnesota", lat: "46.729553", lon: "-94.6859"},
    {value: "MO", name: "Missouri", lat: "37.964253", lon: "-91.831833"},
    {value: "MS", name: "Mississippi", lat: "32.354668", lon: "-89.398528"},
    {value: "MT", name: "Montana", lat: "46.879682", lon: "-110.362566"},
    {value: "NC", name: "North Carolina", lat: "35.759573", lon: "-79.0193"},
    {value: "ND", name: "North Dakota", lat: "47.551493", lon: "-101.002012"},
    {value: "NE", name: "Nebraska", lat: "41.492537", lon: "-99.901813"},
    {value: "NH", name: "New Hampshire", lat: "43.193852", lon: "-71.572395"},
    {value: "NJ", name: "New Jersey", lat: "40.058324", lon: "-74.405661"},
    {value: "NM", name: "New Mexico", lat: "34.97273", lon: "-105.032363"},
    {value: "NV", name: "Nevada", lat: "38.80261", lon: "-116.419389"},
    {value: "NY", name: "New York", lat: "43.299428", lon: "-74.217933"},
    {value: "OH", name: "Ohio", lat: "40.417287", lon: "-82.907123"},
    {value: "OK", name: "Oklahoma", lat: "35.007752", lon: "-97.092877"},
    {value: "OR", name: "Oregon", lat: "43.804133", lon: "-120.554201"},
    {value: "PA", name: "Pennsylvania", lat: "41.203322", lon: "-77.194525"},
    {value: "RI", name: "Rhode Island", lat: "41.580095", lon: "-71.477429"},
    {value: "SC", name: "South Carolina", lat: "33.836081", lon: "-81.163725"},
    {value: "SD", name: "South Dakota", lat: "43.969515", lon: "-99.901813"},
    {value: "TN", name: "Tennessee", lat: "35.517491", lon: "-86.580447"},
    {value: "TX", name: "Texas", lat: "31.968599", lon: "-99.901813"},
    {value: "UT", name: "Utah", lat: "39.32098", lon: "-111.093731"},
    {value: "VA", name: "Virginia", lat: "37.431573", lon: "-78.656894"},
    {value: "VT", name: "Vermont", lat: "44.558803", lon: "-72.577841"},
    {value: "WA", name: "Washington", lat: "47.751074", lon: "-120.740139"},
    {value: "WI", name: "Wisconsin", lat: "43.78444", lon: "-88.787868"},
    {value: "WV", name: "West Virginia", lat: "38.597626", lon: "-80.454903"},
    {value: "WY", name: "Wyoming", lat: "43.075968", lon: "-107.290284"}
];
