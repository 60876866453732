import React, { useState, useEffect } from 'react';
import { StripeProvider } from "./StripeProvider";
import { calcPrice, addParamToQueryString } from "ecommerce-commons-ui/core/utils";
import { dataSourceV2 } from "commons-ui/core/DataSource";
import { SuccessPage } from "./SuccessPage";


const getAmount = (products) => {
  return products.reduce((a, b) => a + (calcPrice(b.product || {}, b.featureList).final_price || 0) * (b.count || 0), 0);
}

export const ExpressCheckout = ({ ecommerceStoreId, loading, setLoading, values={}, onValidateData, deliveryRequestedDate, deliveryRequestedTime, noLoading, recaptchaKey, gift, deliveryOption, rule, ecommerceStoreGroupId, location, apiPath, products, onRedirect, onSuccess, isMobile, dispatch, onInit }) => {
  const deliveryType = rule.delivery_type;
  const isShipping = deliveryType === "shipping" || deliveryType === "shipping_event" || deliveryType === "delivery";

  const [submitted, setSubmitted] = useState(false);
  //const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [amount, setAmount] = useState(getAmount(products));
  //const [deliveryOption, setDeliveryOption] = useState({});
  //const [rule, setRule] = useState({});

  const amountTemp = getAmount(products)


  const statesInclude = (rule.state || "").split(";").filter(v => !!v).map(v => v.toUpperCase());

  //const amount = products.reduce((a, b) => a + (calcPrice(b.product || {}, b.featureList).final_price || 0) * (b.count || 0), 0);
  const productsProcessed = products.map(productView => {
    const product = productView.product || {}
    return {
      name: product.name,
      price: calcPrice(product, productView.featureList).final_price || 0
    }
  });

  const cartItemList = products.map(v => {
    const product = v.product || {};
    return {
      ...v,
      count: v.count,
      product_id: v.id,
      price: parseFloat(v.price || 0),
      price_string: (v.price || 0).toString(),
      name: product.name,
      image: product.image,
      featureDict: v.featureDict || {},
      featureList: v.featureList || []
    };
  });

  const checkoutValidate = async (order) => {
    const deliveryType_ = deliveryType//order.delivery_type
    const deliveryAddress = order.delivery_address || ""
    const deliveryCity = order.delivery_city || ""
    const deliveryPostalCode = order.delivery_postal_code || ""
    const deliveryState = order.delivery_state || ""
    const deliveryName = order.delivery_name || ""
    const deliveryDate = deliveryRequestedDate

    console.log("checkoutValidate", order)

    if (deliveryType_ === "shipping" || deliveryType_ === "delivery" || deliveryType_ === "shipping_event") {
      if (!deliveryPostalCode || !deliveryCity || !deliveryState || !deliveryDate) {
        //return {}
      }
    }
    const now = new Date();
    const data = {
      is_last_step: true,
      is_oneclick: true,
      delivery_type: deliveryType_,
      ecommerce_store_delivery_option_id: rule["ecommerce_store_delivery_option_id"],
      ecommerce_store_delivery_option_pickup_id: rule["ecommerce_store_delivery_option_pickup_id"],
      ecommerce_store_delivery_option_rule_id: rule["ecommerce_store_delivery_option_rule_id"],
      ecommerce_store_delivery_option_event_id: rule["ecommerce_store_delivery_option_event_id"],
      products: products,
      tips_amount_custom: values.tips_amount_custom || 0,
      tips_amount_percent: values.tips_amount_percent || 0,
      ecommerce_store_gift_card_list: values.ecommerce_store_gift_card_list || [],
      promocode: values.promocode || "",
      labeling_amount: values.labeling_amount || 0,
      ecommerce_store_id: ecommerceStoreId,
      order_placed_at: now.toISOString(),
      delivery_requested_at: deliveryDate,
      delivery_name: deliveryName,
      delivery_address: deliveryAddress,
      delivery_city: deliveryCity,
      delivery_postal_code: deliveryPostalCode,
      delivery_state: deliveryState
    }

    const response = await dataSourceV2(`ecommerce_order_validate`, { data: data, url: apiPath })

    if (onValidateData) {
      onValidateData(response.values)
    }
    return response.values
  }



  const onCreateOrder = async (details) => {
    const data = {};
    data.status = "unpaid";
    // INFO
    data.customer_name = details.customer_name || "";
    data.customer_surname = details.customer_surname || "";
    data.customer_phone = details.customer_phone || "";
    data.customer_email = details.customer_email || "";

    // DELIVERY
    data.delivery_type = rule.delivery_type;
    data.ecommerce_store_delivery_option_id = rule["ecommerce_store_delivery_option_id"];
    data.delivery_requested_date = deliveryRequestedDate;
    data.delivery_requested_time = deliveryRequestedTime || "";
    data.delivery_requested_time_exact = null;
    //pick_up
    data.ecommerce_store_delivery_option_pickup_id = "";
    //delivery shipping
    data.delivery_name = details.delivery_name || "";
    data.delivery_surname = details.delivery_surname || "";
    data.delivery_address = details.delivery_address || "";
    data.delivery_address2 = details.delivery_address2 || "";
    data.delivery_city = details.delivery_city || "";
    data.delivery_state = details.delivery_state || "";
    data.delivery_postal_code = details.delivery_postal_code || "";
    data.delivery_phone = details.delivery_phone || "";
    //shipping_air air
    data.delivery_airport_name = "";
    data.ecommerce_store_delivery_option_rule_id = rule["ecommerce_store_delivery_option_rule_id"];
    //event
    data.ecommerce_store_delivery_option_event_id = rule["ecommerce_store_delivery_option_event_id"];
    data.ecommerce_store_delivery_option_pickup_id = rule["ecommerce_store_delivery_option_pickup_id"];

    // PAYMENT
    data.base_amount = details.base_amount || amount;
    data.promocode_amount = details.promocode_amount || 0;
    data.gift_card_amount = details.gift_card_amount || 0;
    data.delivery_amount = details.delivery_amount || 0;
    data.packaging_amount = details.packaging_amount || 0;
    data.labeling_amount = details.labeling_amount || 0;
    data.tax_amount = details.tax_amount || 0;
    data.tips_amount = details.tips_amount || 0;
    data.total_amount = details.total_amount || amount;
    data.tips_amount_percent = 0
    data.tips_amount_custom = 0

    data.billing_name = details.billing_name || "";
    data.billing_address = details.billing_address || "";
    data.billing_postal_code = details.billing_postal_code || "";


    data.payment = {};
    data.ecommerce_store_delivery_option = {};
    data.ecommerce_store_delivery_option_rule = {};
    data.ecommerce_store_delivery_option_event = {};
    data.ecommerce_store_delivery_option_pickup = {};
    data.tax_rate_global = 0;


    data.city_filter = data.delivery_city;
    data.state_filter = data.delivery_state;
    data.postal_code_filter = data.delivery_postal_code;

    data.payment = JSON.stringify({});

    const giftData = gift || {}
    data.is_gift = giftData.is_gift || false
    data.gift_sender_name = giftData.gift_sender_name || ""
    data.gift_recipient_name = giftData.gift_recipient_name || ""
    data.gift_message = giftData.gift_message || ""


    data.ecommerce_store_delivery_option_json = JSON.stringify(deliveryOption || {});

    if (rule.ecommerce_store_delivery_option_event_id) {
      data.ecommerce_store_delivery_option_event_json = JSON.stringify(rule);
    } else if (rule.ecommerce_store_delivery_option_pickup_id) {
      data.ecommerce_store_delivery_option_pickup_json = JSON.stringify(rule);
    } else {
      data.ecommerce_store_delivery_option_rule_json = JSON.stringify(rule);
    }



    const data_ = {
      ecommerce_order: { ...data, ecommerce_store_id: ecommerceStoreId, ecommerce_store_group_id: ecommerceStoreGroupId },
      cart_item_list: cartItemList,
      utm: getUTM()
    };

    console.log("ORDER", data_)


    const unpaidResp = await dataSourceV2("ecommerce_order_unpaid", { data: { item: data_ }, url: apiPath })
    if (unpaidResp.message || unpaidResp.status) {
      return { message: unpaidResp.message || unpaidResp.status }
    }

    const item = unpaidResp.item || {};
    const ecommerceOrderId = item.ecommerce_order_id;
    return { ecommerce_order_id: ecommerceOrderId, order: item }
  }

  const onConfirmOrder = async (ecommerceOrderId) => {
    const resp = await dataSourceV2("ecommerce_order_confirm", { data: { ecommerce_order_id: ecommerceOrderId, payment: {} }, url: apiPath })
    if (resp.message && !resp.message.toLowerCase().includes("confirmed")) {
      console.log("ERROR ORDER CONFIRM", resp.message, ecommerceOrderId);
    } else {
      console.log("ORDER CONFIRMED", ecommerceOrderId)
      setSubmitted(true);
      setTimeout(() => {
        dispatch("cart", {isOpen: false}, "clear_cart");
      }, 10000)
      dispatch(addParamToQueryString("order_placed", "true", location));
      try {
        fbq('trackCustom', 'PlaceAnOrder', {
          value: amount || 0,
          currency: 'USD',
          order_id: ecommerceOrderId || "",
          event_id: ecommerceOrderId || ""
        });
      } catch (error) { }

      onSuccess ? onSuccess({"total_amount": amount}) : null;
    }
  }

  console.log(rule, deliveryOption)

  useEffect(() => {
    checkoutValidate({}).then(v => {
      if (v && v.total_amount && v.total_amount !== amount) {
        setAmount(v.total_amount)
      }
    })
  }, [])

  useEffect(() => {
    console.log("VALUES CHANGED", values)
    checkoutValidate({}).then(v => {
      if (v && v.total_amount && v.total_amount !== amount) {
        setAmount(v.total_amount)
      }
    })
  }, [products.length, products.reduce((total, item) => total + (item.free || 0), 0),  values.tips_amount_custom, values.tips_amount_percent, (values.ecommerce_store_gift_card_list || []).length, values.promocode, values.labeling_amount])
  console.log("values", values)

  useEffect(() => {
    //requestRule()
    setReload(true)
    setLoading(true)
  }, [amount, deliveryRequestedDate])

  useEffect(() => {
    if (reload) {
      setReload(false)
    }
  }, [reload])

  useEffect(() => {
    checkoutValidate({}).then(v => {
      if (v && v.total_amount) {
        setAmount(v.total_amount)
      }
    })
  }, [amountTemp])

  if (reload) {
    return null
  }

  return (
    <div>
      <StripeProvider amount={amount}
        onInit={onInit}
        products={productsProcessed}
        ecommerceStoreId={ecommerceStoreId}
        onCreateOrder={onCreateOrder}
        onConfirmOrder={onConfirmOrder}
        onRedirect={onRedirect}
        noLoading={noLoading}
        apiPath={apiPath}
        recaptchaKey={recaptchaKey}
        deliveryType={deliveryType}
        statesInclude={statesInclude}
        checkoutValidate={checkoutValidate}
        loading={loading}
      />
      <SuccessPage show={submitted} isMobile={isMobile} onClose={() => setSubmitted(false)} dispatch={dispatch} />
    </div>
  );
}

function getUTM() {
  try {
    const utmData = localStorage.getItem('_utm_meta');
    return utmData ? JSON.parse(utmData) : {};
  } catch (e) {
    console.error('Error retrieving UTM parameters from local storage:', e);
    return {};
  }
}