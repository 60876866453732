import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Form } from "formik";
import { dataSourceV2 } from "commons-ui/core/DataSource";
import MaskedInput from "react-text-mask";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {addParamToQueryString} from "commons-ui/core/utils";
import "./style.styl";

function PhoneMask(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => { inputRef(ref ? ref.inputElement : null); }}
            mask={["(", /[2-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={"_"}
        />
    );
}


export default function Contacts(props) {
    const { values, errors, onNextStep, touched, onBack, login, readOnly, setFieldValue, isSubmitting, location, apiPath, dispatch } = props;
    const [withLoginAlert, setWithLoginAlert] = useState(false);
    const [isLoginAlertShow, setIsLoginAlertShow] = useState(false);

    const hasErrors = Object.keys(errors).length

    const isAccountExists = (email) => {
        if (login && login.token) {
            setWithLoginAlert(false)
            return;
        }
        if (!email || !email.includes("@") || email.length < 5 || !email.includes(".")) {
            setWithLoginAlert(false)
            console.log("email invalid")
            return
        }
        dataSourceV2(`is_account_exists?email=${email.trim().toLowerCase()}`, { url: apiPath })
            .then(v => {
                if (v.is_exists) {
                    setWithLoginAlert(true)
                } else {
                    setWithLoginAlert(false)
                }
            });
    };

    useEffect(() => {
        if (login.token && isLoginAlertShow) {
            onNextStep()
        }
    }, [login.token])

    useEffect(() => {
        console.log("hasErrors", hasErrors, isLoginAlertShow)
        if (hasErrors && isLoginAlertShow) {
            setIsLoginAlertShow(false)
        }
    }, [hasErrors])


    return (
        <Form>
            <div className="u-flexColumn">
                <span className="u-fontWeightBold u-fontSize18">Contacts</span>
                <div className="u-paddingTop8">
                    <div className="u-flexCenter u-xs-flexColumn">
                        <TextField
                            error={errors.customer_name && touched.customer_name}
                            helperText={errors.customer_name && touched.customer_name ? errors.customer_name : ""}
                            label="First Name *"
                            margin="dense"
                            disabled={readOnly}
                            inputProps={{ maxLength: 300 }}
                            value={values.customer_name}
                            className="u-sizeFullWidth u-marginVertical12 u-marginRight10 u-xs-marginRight0"
                            onChange={(e) => setFieldValue("customer_name", e.target.value)}
                            variant="outlined"
                        />
                        <TextField
                            error={errors.customer_surname && touched.customer_surname}
                            helperText={errors.customer_surname && touched.customer_surname ? errors.customer_surname : ""}
                            label="Last Name *"
                            margin="dense"
                            inputProps={{ maxLength: 300 }}
                            disabled={readOnly}
                            value={values.customer_surname}
                            className="u-sizeFullWidth u-marginVertical12 u-marginLeft10 u-xs-marginLeft0"
                            onChange={(e) => setFieldValue("customer_surname", e.target.value)}
                            variant="outlined"
                        />
                    </div>

                    <div className="u-flex u-xs-flexColumn">
                        <TextField
                            error={errors.customer_phone && touched.customer_phone}
                            helperText={errors.customer_phone && touched.customer_phone ? errors.customer_phone : "By providing your phone number, you agree that we may text you with reminders and other info. Reply STOP to opt out of text messaging.  Standard rates apply."}
                            label="Phone *"
                            margin="dense"
                            disabled={readOnly}
                            value={values.customer_phone}
                            className="u-sizeFullWidth u-marginVertical12 u-marginRight10 u-xs-marginRight0"
                            onChange={(e) => setFieldValue("customer_phone", e.target.value)}
                            variant="outlined"
                            InputProps={{ inputComponent: PhoneMask }}
                        />
                        <TextField
                            error={errors.customer_email && touched.customer_email}
                            helperText={errors.customer_email && touched.customer_email ? errors.customer_email : ""}
                            label="Email *"
                            margin="dense"
                            disabled={readOnly}
                            value={values.customer_email}
                            className="u-sizeFullWidth u-marginVertical12 u-marginLeft10 u-xs-marginLeft0"
                            onChange={(e) => setFieldValue("customer_email", e.target.value ? e.target.value.trim() : e.target.value)}
                            onBlur={(e) => isAccountExists(e.target.value)}
                            variant="outlined"
                        />
                    </div>

                    <div className="u-flexCenter u-xs-flexColumn">
                        <TextField
                            label="Comments (Special Instructions)"
                            className="u-sizeFullWidth u-marginVertical12 u-marginRight10 u-xs-marginRight0"
                            margin="dense"
                            multiline
                            rows={2}
                            inputProps={{ maxLength: 500 }}
                            disabled={readOnly}
                            error={errors.customer_note && touched.customer_note}
                            helperText={errors.customer_note && touched.customer_note ? errors.customer_note : ""}
                            onChange={(e) => setFieldValue("customer_note", e.target.value)}
                            value={values.customer_note}
                            variant="outlined"
                        />
                        <div className="u-sizeFullWidth u-marginVertical12 u-marginLeft10 u-xs-marginLeft0">
                        </div>
                    </div>
                </div>
                {
                    !readOnly &&
                    <div className="u-marginBottom100 u-marginTop20">
                        <Button className="button-secondary" size="large" onClick={withLoginAlert ? () => setIsLoginAlertShow(true) : onNextStep}>
                            Continue
                        </Button>

                        {
                            hasErrors ?
                                <div className="MuiFormHelperText-root Mui-error u-marginTop10">See errors above</div>
                                :
                                null
                        }
                    </div>
                }

            </div>

            <Dialog
                onClose={() => null}
                open={isLoginAlertShow}
                maxWidth="md"
                className=""
            >
                <DialogTitle className="u-textColorRed">
                    Welcome back, Piroshky Fam!
                </DialogTitle>
                <DialogContent>
                    <p className="u-maxWidth600 u-marginBottom15">
                        Our system recognized your email address. It's good to see you again!
                        <br/><br/>
                        Make sure to login to your account to earn loyalty points on your order.
                        <br/><br/>
                        If you do not login, you will not earn points. 
                    </p>
                </DialogContent>
                <DialogActions className="u-flexColumn u-alignCenter u-justifyContentCenter">
                    <Button variant="outlined" size="large" className="button-primary" onClick={() => dispatch(addParamToQueryString("auth_dialog", "login__" + values.customer_email, location))}>
                        Login and Earn Points
                    </Button>
                    <span className="link u-marginTop20 u-marginBottom20 u-fontSize15" onClick={() => { setIsLoginAlertShow(false); onNextStep(); }}>
                        No Points for me
                    </span>
                </DialogActions>
            </Dialog>
        </Form>
    );
}
