import React, { useState, useEffect } from "react";
import { useRouter } from "../../../hooks/useRouter";
import queryString from "query-string";
import cookie from "react-cookie";

import { Page } from "./Page";


import { useStore } from "../../../context/state";

import { dataSourceV2 } from "commons-ui/core/DataSource";

import "./SignUpView.module.scss";

export default function SignUpView({onSuccess, onLogin}) {
  const { apiPath } = useStore();

  const [type, setType] = useState("login");
  const [loading, setLoading] = useState(true);

  const location = useRouter();

  const queryParams = queryString.parse(location.search);
  const options = { ...queryParams };

  const onCheck = () => {
    const deviceId = cookie.load("device_id");
    if (!deviceId) {
      setLoading(false);
      setType("login");
      return;
    }
    dataSourceV2(`login_check?device_id=${deviceId}`, { url: apiPath }).then(
      (v) => {
        if (v.type) {
          setType(v.type);
          setLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    onCheck();
  }, []);

  return (
    <Page type={type} loading={loading} options={options} onCheck={onCheck} onLogin={onLogin} onSuccess={onSuccess} />
  );
}
