import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {dataSourceV2} from "commons-ui/core/DataSource";
import {Form, withFormik} from "formik";
import cookie from "react-cookie";
import MaskedInput from "react-text-mask";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import { DatePicker } from "@material-ui/pickers";
import {addParamToQueryString} from "commons-ui/core/utils";
import "./style.styl";


function PhoneMask(props) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref => { inputRef(ref ? ref.inputElement : null); }}
        mask={["(", /[2-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={"_"}
      />
    );
  }


function SigninForm(props) {
    const {values, errors, touched, onBack, setFieldValue, onTab, dispatch, isSubmitting} = props;

    return (
            <Form className="u-flexColumn">
                <div>
                    <TextField
                        label="First Name *"
                        className="u-sizeFullWidth u-marginVertical10"
                        margin="dense"
                        error={errors.name && touched.name}
                        helperText={errors.name && touched.name ? errors.name : ""}
                        onChange={(e) => setFieldValue("name", e.target.value)}
                        value={values.name}
                        variant="outlined"
                    />
                </div>
                <div>
                    <TextField
                        label="Last Name *"
                        className="u-sizeFullWidth u-marginVertical10"
                        margin="dense"
                        error={errors.surname && touched.surname}
                        helperText={errors.surname && touched.surname ? errors.surname : ""}
                        onChange={(e) => setFieldValue("surname", e.target.value)}
                        value={values.surname}
                        variant="outlined"
                    />
                </div>
                <div>
                    <TextField
                        error={errors.postal_code && touched.postal_code}
                        label="Zip Code *"
                        helperText={errors.postal_code && touched.postal_code ? errors.postal_code : ""}
                        margin="dense"
                        className="u-sizeFullWidth u-marginVertical12"
                        onChange={(e) => setFieldValue("postal_code", e.target.value)}
                        variant="outlined"
                    />
                </div>
                <div>
                    <DatePicker
                      disableFuture
                      openTo="year"
                      variant="inline"
                      ampm={false}
                      className="u-sizeFullWidth u-marginVertical12"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      views={["year", "month", "date"]}
                      margin="dense"
                      id="date-picker-birthday"
                      label="Birthday"
                      error={errors.date_of_birth && touched.date_of_birth}
                      helperText={(errors.date_of_birth && touched.date_of_birth) ? errors.date_of_birth : undefined}
                      value={values.date_of_birth ? new Date(values.date_of_birth) : null}
                      onChange={(date) => {
                            let value = null
                            if (!date || isNaN(date.getTime())) {
                                value = null
                            } else {
                                date.setHours(12);
                                value = date.toISOString()
                            }
                            setFieldValue("date_of_birth", value)
                            
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                  />
                </div>


                <div className="u-marginTop15">
                    <TextField
                        label="Email *"
                        margin="dense"
                        className="u-sizeFullWidth u-marginVertical10"
                        error={errors.email && touched.email}
                        helperText={errors.email && touched.email ? errors.email : ""}
                        autoComplete="new-password"
                        onChange={(e) => setFieldValue("email", e.target.value)}
                        value={values.email}
                        variant="outlined"
                    />
                </div>
                <div>
                <TextField
                    error={errors.phone && touched.phone}
                    helperText={errors.phone && touched.phone ? errors.phone : ""}
                    label="Phone *"
                    margin="dense"
                    value={values.phone}
                    className="u-sizeFullWidth u-marginVertical12"
                    onChange={(e) => setFieldValue("phone", e.target.value)}
                    variant="outlined"
                    InputProps={{ inputComponent: PhoneMask }}
                />
                </div>
                <div>
                    <TextField
                        label="Password *"
                        className="u-sizeFullWidth u-marginVertical10"
                        type="password"
                        margin="dense"
                        error={errors.password && touched.password}
                        helperText={errors.password && touched.password ? errors.password : ""}
                        autoComplete="new-password"
                        onChange={(e) => setFieldValue("password", e.target.value)}
                        value={values.password}
                        variant="outlined"
                    />
                </div>
                <div>
                    <div>
                        <FormControl
                            error={errors.over_18 && touched.over_18}
                            className="u-marginTop12"
                            component="fieldset">
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="secondary"
                                            onChange={(e) => setFieldValue("over_18", event.target.checked)}
                                            checked={values.over_18}
                                            className=""/>
                                    }
                                    label="I am over 18"
                                    />
                            </FormGroup>
                            <FormHelperText>
                                {errors.over_18 && touched.over_18 ? errors.over_18 : ""}
                            </FormHelperText>
                        </FormControl>
                    </div>

                    <FormControl
                        error={errors.confirm_license_agreement && touched.confirm_license_agreement}
                        className=""
                        component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="secondary"
                                        onChange={(e) => setFieldValue("confirm_license_agreement", event.target.checked)}
                                        checked={values.confirm_license_agreement}
                                        className=""/>
                                }
                                label={<span className="link" onClick={() => dispatch("/terms-and-conditions")}>I read and agree with license agreement</span>}
                                />
                        </FormGroup>
                        <FormHelperText>
                            {errors.confirm_license_agreement && touched.confirm_license_agreement ? errors.confirm_license_agreement : ""}
                        </FormHelperText>
                    </FormControl>
                </div>
                <div className="u-marginVertical10">
                    <div className="u-flexColumn u-justifyContentCenter u-alignItemsCenter">
                        <Button
                                disabled={isSubmitting}
                                type="submit"
                                variant="outlined"
                                size="large"
                                className="button-secondary">
                            Create Account
                        </Button>
                        <div className="comment u-marginTop10">
                            Already have an account? <span className="link" onClick={() => onTab()}>Sign In</span>
                        </div>
                    </div>
                </div>
            </Form>
    );
}

export const emailRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;



export default SigninForm = withFormik({
    mapPropsToValues: (props) => ({
        name: "",
        surname: "",
        postal_code: "",
        date_of_birth: "",
        email: "",
        phone: "",
        password: "",
        over_18: false,
        confirm_license_agreement: false
    }),

    validate: (values, props) => {
        let errors = {};

        if (!values.name) {
            errors.name = "Cannot be empty";
        }
        if (!values.surname) {
            errors.surname = "Cannot be empty";
        }
        if (!values.postal_code) {
            errors.postal_code = "Cannot be empty";
        }
        if (!emailRe.test(values.email)) {
            errors.email = "Incorrect email";
        }
        if (!values.phone) {
            errors.phone = "Cannot be empty";
        }
        if (!values.password) {
            errors.password = "Incorrect Password";
        }
        if (!values.over_18) {
            errors.over_18 = "You must be over 18";
        }
        if (!values.confirm_license_agreement) {
            errors.confirm_license_agreement = "Submit our license agreement";
        }
        return errors;
    },

    handleSubmit: (values, props) => {
        const {onSubmit, path, onError, onSuccess, onCheck, dispatch, apiPath, ecommerceStoreId} = props.props;
        const {setFieldError} = props;
        values.email = values.email.toLowerCase();
        values.username = values.email;
        values.ecommerce_store_id = ecommerceStoreId
        dataSourceV2(path || "customer_with_workspace_create", {data: {item: values}, url: apiPath})
            .then(v=>{

                if (v.message) {
                    const err = v.message || "";
                    if (err.includes("exists")) {
                        setFieldError("email", "Email exists");
                    }
                    props.setSubmitting(false);
                } else {
                    dataSourceV2(path || "sign_in", {data: {...values, device_id: cookie.load("device_id") || ""}, url: apiPath})
                        .then(v=>{
                            const token = v.access || v.token;
                            if (token) {
                                cookie.save("token", token, {path: "/"});
                                if (v.refresh) {
                                    cookie.save("token_refresh", v.refresh, {path: "/"});
                                }
                                dispatch("login", {"token": token});
                                props.setSubmitting(false);
                                onSuccess();
                            } else if (v.device_id) {
                                cookie.save("device_id", v.device_id, {path: "/"});
                                onCheck();
                            } else {
                                const err = v.message || "";
                                if (err.includes("incorrect email")) {
                                    setFieldError("email", "Incorrect email");
                                } else if (err.includes("incorrect password")) {
                                    setFieldError("password", "Incorrect password");
                                } else {
                                    setFieldError("email", "Try again later");
                                }
                                props.setSubmitting(false);
                            }
                        });
                }
            });
    }

})(SigninForm);
