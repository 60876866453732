import React from "react";

import SignUpForm from "./SignUpForm";
import ConfirmForm from "./ConfirmForm";

import { useStore } from "../../../context/state";

import Loading from "commons-ui/core/Loading";

export const Page = ({ type, loading, options, onCheck, onSuccess, onLogin }) => {
  const { dispatch, apiPath, ecommerceStoreId } = useStore();

  if (loading) {
    return (
      <div className="container background-wrapper">
        <div
          className="paper-box u-marginTop100 u-padding25 u-flexCenter u-justifyContentCenter"
        >
          <Loading color="#50019f" />
        </div>
      </div>
    );
  }


  return (
    <div>
      <div
        className=""
      >
        {loading ? (
          <Loading color="#50019f" />
        ) : type === "confirmation" ? (
          <ConfirmForm
            options={options}
            onCheck={onCheck}
            apiPath={apiPath}
            dispatch={dispatch}
            onLogin={onLogin}
            onSuccess={() => onSuccess()}
          />
        ) : (
          <SignUpForm
            options={options}
            onCheck={onCheck}
            apiPath={apiPath}
            dispatch={dispatch}
            onLogin={onLogin}
            ecommerceStoreId={ecommerceStoreId}
            onSuccess={() => onSuccess()}
          />
        )}
      </div>
    </div>
  );
};
