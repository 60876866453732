import React, { useState } from "react";
import cookie from "react-cookie";
import queryString from "query-string";
import { useRouter } from "../../../hooks/useRouter";

import { Page } from "./Page";


import { useStore } from "../../../context/state";

import { dataSourceV2 } from "commons-ui/core/DataSource";

export default function LoginView({onSignUp, onSuccess, options}) {
  const { apiPath } = useStore();

  const location = useRouter();

  const [type, setType] = useState("login");
  const [loading, setLoading] = useState(true);

  const queryParams = queryString.parse(location.search);
  const options_ = { ...queryParams, ...(options || {})};

  const onCheck = () => {
    const deviceId = cookie.load("device_id");
    if (!deviceId) {
      setLoading(false);
      setType("login");
      return;
    }
    dataSourceV2(`login_check?device_id=${deviceId}`, { url: apiPath }).then(
      (v) => {
        if (v.type) {
          setType(v.type);
          setLoading(false);
        }
      }
    );
  };

  return (
    <Page type={type} loading={loading} options={options_} onCheck={onCheck} onSignUp={onSignUp} onSuccess={onSuccess} />
  );
}

const token = cookie.load("token");

export const login = {
  token: token === "undefined" ? "" : token,
};

export const logout = (dispatch) => {
  cookie.remove("token", { path: "/" });
  cookie.remove("token_refresh", { path: "/" });
  if (dispatch) {
    dispatch();
  }
};
