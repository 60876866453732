import React from "react";
import { Form, withFormik } from "formik";
import cookie from "react-cookie";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { dataSourceV2 } from "commons-ui/core/DataSource";

import "./ConfirmForm.module.scss";

export const emailRe =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function ConfirmForm(props) {
  const { values, errors, touched, isSubmitting, setFieldValue, onCheck } =
    props;

  const goBack = () => {
    cookie.remove("device_id", { path: "/" });
    onCheck();
  };

  return (
    <Form
      noValidate=""
      className="u-paddingTop40 u-paddingBottom50 u-paddingHorizontal24"
      autoComplete="off"
    >
      <div className="u-flexColumn u-alignItemsCenter u-sizeFullWidth">
        <div className="u-flexColumn u-alignItemsCenter u-marginBottom15">
          <div className="u-fontSize28 u-fontWeightBold">
            Confirm Your Sign In
          </div>
        </div>
        <div className="u-maxWidth400">
          <span className="u-textAlignCenter">
            We sent a confirmation code to your email. Enter the confirmation
            code.
          </span>

          <TextField
            error={errors.access_code && touched.access_code}
            label="Access code"
            helperText={
              errors.access_code && touched.access_code
                ? errors.access_code
                : ""
            }
            margin="dense"
            variant="outlined"
            className="u-sizeFullWidth u-marginVertical12"
            onChange={(e) => setFieldValue("access_code", e.target.value)}
            value={values.access_code}
          />

          <span
            className="link u-marginTop30"
            onClick={() => {
              goBack();
            }}
          >
            Cancel
          </span>
        </div>
        <div className="u-marginTop20">
          <Button
            variant="outlined"
            type="submit"
            size="large"
            className="button-secondary"
            disabled={isSubmitting}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Form>
  );
}

export default ConfirmForm = withFormik({
  mapPropsToValues: (props) => ({
    access_code: "",
  }),

  validate: (values, props) => {
    let errors = {};
    if (!values.access_code) {
      errors.access_code = "Incorrect access code";
    }
    if (values.access_code.length !== 5) {
      errors.access_code = "Incorrect access code";
    }
    return errors;
  },

  handleSubmit: (values, props) => {
    const { dispatch, path, onSuccess, apiPath } = props.props;
    const { setFieldError } = props;

    dataSourceV2(path || "validate_code", {
      data: {
        code: values.access_code,
        device_id: cookie.load("device_id") || "",
      },
      url: apiPath,
    }).then((v) => {
      const token = v.access || v.token;
      if (token) {
        cookie.save("token", token, { path: "/" });
        if (v.refresh) {
          cookie.save("token_refresh", v.refresh, { path: "/" });
        }
        dispatch("login", { token: token });
        props.setSubmitting(false);
        onSuccess();
      } else {
        setFieldError("access_code", "Incorrect access code");
        props.setSubmitting(false);
      }
    });
  },
})(ConfirmForm);
