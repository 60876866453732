import React from "react";
import { Form, withFormik } from "formik";
import cookie from "react-cookie";

import { useStore } from "../../../../context/state";
import MaskedInput from "react-text-mask";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { DatePicker } from "@material-ui/pickers";
import { dataSourceV2 } from "commons-ui/core/DataSource";

import "./SignUpForm.module.scss";

export const emailRe =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function PhoneMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => { inputRef(ref ? ref.inputElement : null); }}
      mask={["(", /[2-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"_"}
    />
  );
}

function isUnderSixteen(dob) {
  const currentDate = new Date();
  const birthDate = new Date(dob);
  let age = currentDate.getFullYear() - birthDate.getFullYear();
  const m = currentDate.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && currentDate.getDate() < birthDate.getDate())) {
    age--;
  }

  return age < 16;
}


function SignUpForm(props) {
  const { values, errors, touched, isSubmitting, onLogin, setFieldValue } = props;
  const { dispatch } = useStore();

  const isAbove16 = values.date_of_birth ? isUnderSixteen(values.date_of_birth) : false

  return (
    <Form
      noValidate=""
      className="u-paddingTop0"
      autoComplete="off"
    >
      <div className="u-flexColumn u-alignItemsCenter u-sizeFullWidth">
        <div className="u-flexColumn u-alignItemsCenter u-marginBottom5">
          <div className="u-fontSize22 u-fontWeightBold u-textColorDark">
            Create Account
          </div>
          <div className="comment">
            Already have an account?{" "}
            <span
              className="link"
              onClick={() => onLogin()}
            >
              Login
            </span>
          </div>
        </div>
        <div className="u-maxWidth400">
          <TextField
            error={errors.email && touched.email}
            label="Email *"
            helperText={errors.email && touched.email ? errors.email : ""}
            margin="dense"
            autoComplete="off"
            autoComplete="new-password"
            className="u-sizeFullWidth u-marginVertical12"
            onChange={(e) => setFieldValue("email", e.target.value)}
            variant="outlined"
          />
          <TextField
            error={errors.phone && touched.phone}
            helperText={errors.phone && touched.phone ? errors.phone : ""}
            label="Phone *"
            margin="dense"
            autoComplete="off"
            autoComplete="new-password"
            value={values.phone}
            className="u-sizeFullWidth u-marginVertical12"
            onChange={(e) => setFieldValue("phone", e.target.value)}
            variant="outlined"
            InputProps={{ inputComponent: PhoneMask }}
          />
          <TextField
            error={errors.password && touched.password}
            label="Password *"
            helperText={
              errors.password && touched.password ? errors.password : ""
            }
            margin="dense"
            type="password"
            autoComplete="off"
            autoComplete="new-password"
            className="u-sizeFullWidth u-marginVertical12"
            onChange={(e) => setFieldValue("password", e.target.value)}
            variant="outlined"
          />

          <div className="u-marginTop15"></div>

          <TextField
            error={errors.name && touched.name}
            label="Your Name *"
            helperText={errors.name && touched.name ? errors.name : ""}
            margin="dense"
            className="u-sizeFullWidth u-marginVertical12"
            onChange={(e) => setFieldValue("name", e.target.value)}
            variant="outlined"
          />

          <TextField
            error={errors.surname && touched.surname}
            label="Your Surname *"
            helperText={errors.surname && touched.surname ? errors.surname : ""}
            margin="dense"
            className="u-sizeFullWidth u-marginVertical12"
            onChange={(e) => setFieldValue("surname", e.target.value)}
            variant="outlined"
          />

          <TextField
            error={errors.postal_code && touched.postal_code}
            label="Zip Code *"
            helperText={errors.postal_code && touched.postal_code ? errors.postal_code : ""}
            margin="dense"
            className="u-sizeFullWidth u-marginVertical12"
            onChange={(e) => setFieldValue("postal_code", e.target.value)}
            variant="outlined"
          />

          <DatePicker
            disableFuture
            openTo="year"
            variant="inline"
            ampm={false}
            className="u-sizeFullWidth u-marginVertical12"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            views={["year", "month", "date"]}
            margin="dense"
            id="date-picker-birthday"
            label="Birthday"
            error={errors.date_of_birth && touched.date_of_birth}
            helperText={(errors.date_of_birth && touched.date_of_birth) ? errors.date_of_birth : undefined}
            value={values.date_of_birth ? new Date(values.date_of_birth) : null}
            onChange={(date) => {
              let value = null
              if (!date || isNaN(date.getTime())) {
                value = null
              } else {
                date.setHours(12);
                value = date.toISOString()
              }
              setFieldValue("date_of_birth", value)

            }}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </div>
        {
          isAbove16 ?
            <span className="u-textColorRed u-marginTop10">You must be at least 16 years old</span>
            :
            null
        }
        <div className="u-marginTop20">
          <Button
            variant="outlined"
            type="submit"
            size="large"
            className="button-secondary u-minWidth120"
            disabled={isSubmitting || isAbove16}
          >
            Sign up
          </Button>
        </div>
        <div className="u-marginTop10">
           <span
              className="link"
              onClick={() => onLogin()}
            >
              or login
            </span>
        </div>
      </div>
    </Form>
  );
}

export default SignUpForm = withFormik({
  mapPropsToValues: (props) => ({
    email: "",
    phone: "",
    password: "",
    name: "",
    surname: "",
    date_of_birth: "",
    postal_code: ""
  }),

  validate: (values, props) => {
    let errors = {};
    if (!emailRe.test(values.email)) {
      errors.email = "Incorrect email";
    }
    if (!values.email) {
      errors.email = "Cannot be empty";
    }
    if (!values.phone) {
      errors.phone = "Cannot be empty";
    }
    if (values.password.length <= 5) {
      errors.password = "Password less than 5 symbols";
    }
    if (!values.password) {
      errors.password = "Cannot be empty";
    }
    if (!values.name) {
      errors.name = "Cannot be empty";
    }
    if (!values.surname) {
      errors.surname = "Cannot be empty";
    }
    if (!values.postal_code) {
      errors.postal_code = "Cannot be empty";
    }
    //if (!values.date_of_birth) {
    //errors.date_of_birth = "Cannot be empty";
    //}
    return errors;
  },

  handleSubmit: (values, props) => {
    const { path, onError, onSuccess, onCheck, dispatch, apiPath, ecommerceStoreId } =
      props.props;
    const { setFieldError } = props;
    values.username = values.email;
    values.ecommerce_store_id = ecommerceStoreId
    dataSourceV2(path || "customer_with_workspace_create", {
      data: { item: { ...values, email: values.email.toLowerCase() } },
      url: apiPath,
    }).then((v) => {
      if (v.message) {
        const err = v.message || "";
        if (err.includes("exists")) {
          setFieldError("email", "Email exists");
        }
        props.setSubmitting(false);
      } else {
        dataSourceV2(path || "sign_in", {
          data: { ...values, device_id: cookie.load("device_id") || "" },
          url: apiPath,
        }).then((v) => {
          const token = v.access || v.token;
          if (token) {
            cookie.save("token", token, { path: "/" });
            if (v.refresh) {
              cookie.save("token_refresh", v.refresh, { path: "/" });
            }
            dispatch("login", { token: token });
            props.setSubmitting(false);
            onSuccess();
          } else if (v.device_id) {
            cookie.save("device_id", v.device_id, { path: "/" });
            onCheck();
          } else {
            const err = v.message || "";
            if (err.includes("exists")) {
              setFieldError("email", "User Exists");
            } else if (err.includes("incorrect password")) {
              setFieldError("password", "Incorrect password");
            } else {
              setFieldError("email", "Try again later");
            }
            props.setSubmitting(false);
          }
        });
      }
    });
  },
})(SignUpForm);
