import React, { useState, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import LoginForm from "./LoginForm";
import SigninForm from "./SigninForm";
import queryString from "query-string";
import ConfirmForm from "./ConfirmForm";
import cookie from "react-cookie";
import Upsell from "ecommerce-commons-ui/core/Upselling";
import UpsellingV2 from "ecommerce-commons-ui/core/UpsellingV2";
import { dataSourceV2 } from "commons-ui/core/DataSource";
import "./style.styl";

export default function Authorization(props) {
  const {
    isLogin,
    cart,
    values,
    errors,
    touched,
    onBack,
    setFieldValue,
    onNextStep,
    height,
    apiPath,
    location,
  } = props;
  const [tab, setTab] = useState("login");
  const [loading, setLoading] = useState(true);
  const queryParams = queryString.parse(location.search);
  const options = { ...queryParams };

  const onCheck = () => {
    const deviceId = cookie.load("device_id");
    if (!deviceId) {
      setLoading(false);
      setTab("login");
      return;
    }
    dataSourceV2(`login_check?device_id=${deviceId}`, { url: apiPath }).then(
      (v) => {
        if (v.type) {
          setTab(v.type);
          setLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    if (tab === "login") onCheck();
  }, [tab]);

  useEffect(() => {
    if (isLogin) onNextStep();
  }, []);

  return (
    <div className="u-flexColumn">
      {/*<Tabs
                    className="flat-tabs u-paddingHorizontal15 u-borderBottom1 u-borderColorLightest"
                    value={tab}
                    onChange={(event, tab) => setTab(tab)}
                >
                    <Tab label="Sign Up" value="signin"/>
                    <Tab label="Sign In" value="login"/>
                </Tabs>*/}
      <div className="u-sizeFullWidth u-flexCenter u-justifyContentCenter">
        <span className="u-fontWeightBold u-fontSize18">Check Out</span>
      </div>
      <div className="u-padding15">
        {tab === "signin" ? (
          <SigninForm
            {...props}
            onTab={() => setTab("login")}
            onError={() => setTab("login")}
            onCheck={onCheck}
            onSuccess={() => onNextStep()}
          />
        ) : tab === "confirmation" ? (
          <ConfirmForm
            {...props}
            options={options}
            onCheck={onCheck}
            onSuccess={() => onNextStep()}
          />
        ) : (
          <LoginForm
            {...props}
            onTab={() => setTab("signin")}
            options={options}
            onCheck={onCheck}
            onSuccess={() => onNextStep()}
          />
        )}
      </div>
      <div>
        <div className="u-flexColumn u-justifyContentCenter u-alignItemsCenter">
          <div className="comment u-marginTop10 u-marginBottom10">
            To check out without signing in
          </div>
          <Button
            onClick={onNextStep}
            variant="outlined"
            size="large"
            className="button-secondary"
          >
            Continue
          </Button>
          <div
            style={{ fontSize: "14px" }}
            className="comment u-marginTop20 u-marginBottom10 u-textAlignCenter"
          >
            By clicking continue you are accepting our <a className="link" style={{color: "#336594"}}  href="/policy-center/terms-and-conditions" target="blank">Terms of Service</a>.
          </div>
        </div>
      </div>
    </div>
  );
}
