import React, {useState, useEffect, useMemo} from "react";
import Button from "@material-ui/core/Button";
import Loading from "commons-ui/core/Loading";
import renderHTML from "react-render-html";
import "./style.styl";




export default function RedirectForm(props) {
    const {item, description, itemId, modelName, itemIdParent, itemIdValueParent, errors, touched, onBack, setFieldValue, isSubmitting, apiPath} = props;


    return (
            <React.Fragment>
                <div>
                    {
                        item.description &&
                            <div className="u-marginBottom20 u-textColorNormal u-fontSize10 u-maxWidth500">
                                {renderHTML(item.description)}
                            </div>
                    }
                    <a href={item.redirect} target="_blank">
                        <Button className="button-secondary" size="large">
                            Go to Website
                        </Button>
                    </a>
                </div>
            </React.Fragment>
    );
}
