import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {dataSourceV2} from "commons-ui/core/DataSource";
import {Form, withFormik} from "formik";
import cookie from "react-cookie";
import "./style.styl";


function LoginForm(props) {
    const {values, errors, touched, onBack, setFieldValue, onTab, dispatch, withPasswordReset, isSubmitting} = props;

    return (
            <Form className="u-flexColumn">
                <div>
                    <TextField
                        label="Email"
                        className="u-sizeFullWidth u-marginVertical10"
                        margin="dense"
                        autoComplete="new-password"
                        error={errors.email && touched.email}
                        helperText={errors.email && touched.email ? errors.email : ""}
                        onChange={(e) => setFieldValue("email", e.target.value)}
                        value={values.email}
                        variant="outlined"
                    />
                </div>
                <div>
                    <TextField
                        label="Password"
                        className="u-sizeFullWidth  u-marginVertical10"
                        type="password"
                        margin="dense"
                        autoComplete="new-password"
                        error={errors.password && touched.password}
                        helperText={errors.password && touched.password ? errors.password : ""}
                        onChange={(e) => setFieldValue("password", e.target.value)}
                        value={values.password}
                        variant="outlined"
                    />
                </div>
                <div className="u-marginVertical10">
                    <div className="u-flexColumn u-justifyContentCenter u-alignItemsCenter">
                        <Button
                                disabled={isSubmitting}
                                type="submit"
                                variant="outlined"
                                size="large"
                                className="button-secondary">
                            Sign In
                        </Button>
                        <div className="comment u-marginTop10">
                            Dont have an account? <span className="link" onClick={() => onTab()}>Sign Up</span>
                        </div>
                        {
                          withPasswordReset ?
                            <div className="comment u-marginTop5">
                                Forgot your password? <span className="link" onClick={() => dispatch("/reset-password")}>Reset Password</span>
                            </div>
                            :
                            null
                        }

                    </div>
                </div>
            </Form>
    );
}

export const emailRe = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


export default LoginForm = withFormik({
    mapPropsToValues: (props) => ({
        email: props.options ? props.options.email || "" : "",
        password: ""
    }),


    validate: (values, props) => {
        let errors = {};
        if (!emailRe.test(values.email)) {
            errors.email = "Incorrect Email";
        }
        if (!values.password) {
            errors.password = "Incorrect Password";
        }
        return errors;
    },

    handleSubmit: (values, props) => {
        const {dispatch, path, onSuccess, onCheck, apiPath} = props.props;
        const {setFieldError} = props;
        values.email = values.email.toLowerCase();
        dataSourceV2(path || "sign_in", {data: {...values, device_id: cookie.load("device_id") || ""}, url: apiPath})
            .then(v=>{
                props.setSubmitting(false);
                if (v.message) {
                    const err = v.message || "";
                    if (err.includes("cred")) {
                        setFieldError("email", "Invalid Credentials");
                    } else if (err.includes("role")) {
                        setFieldError("email", "Incorrect role");
                    } else {
                        setFieldError("email", "Try again later");
                    }
                    return;
                }
                const token = v.access || v.token;
                if (token) {
                    cookie.save("token", token, {path: "/"});
                    if (v.refresh) {
                        cookie.save("token_refresh", v.refresh, {path: "/"});
                    }
                    dispatch("login", {"token": token});
                    props.setSubmitting(false);
                    onSuccess();
                } else if (v.device_id) {
                    cookie.save("device_id", v.device_id, {path: "/"});
                    onCheck();
                } else {
                    const err = v.message || "";
                    if (err.includes("incorrect email")) {
                        setFieldError("email", "Incorrect email");
                    } else if (err.includes("incorrect password")) {
                        setFieldError("password", "Incorrect password");
                    } else {
                        setFieldError("email", "Try again later");
                    }
                    props.setSubmitting(false);
                }
            });
    }

})(LoginForm);
