import React, {useState, useEffect, useMemo} from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "@material-ui/core/Button";
import {dataSourceV2} from "commons-ui/core/DataSource";
import Loading from "commons-ui/core/Loading";
import PickUpForm from "./PickUpForm";
import ShippingForm from "./ShippingForm";
import DeliveryForm from "./DeliveryForm";
import RedirectForm from "./RedirectForm";
import AirForm from "./AirForm";
import EventForm from "./EventForm";
import "./style.styl";

export const calcDeliveryCost = (orderAmount, deliveryCostJson, defaultDeliveryCost) => {
    let cost = defaultDeliveryCost || 0
    try {
        const rules = JSON.parse(deliveryCostJson || "[]")
        for (let i = 0; i < rules.length; i++) {
            const rule = rules[i]
            if (orderAmount >=0 && rule.amount_min >= 0 && rule.amount_max >= 0 && rule.cost >= 0 && orderAmount >= rule.amount_min && orderAmount <= rule.amount_max) {
                cost = rule.cost
                break
            }
        }
    } catch (exceptionVar) {
    }
    return cost
}


export default function Delivery(props) {
    const {values, callbackItem, modelName, errors, touched, itemId, onNextStep, itemIdParent, readOnly, readOnlySelect, itemIdValueParent, setFieldValue, apiPath} = props;
    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState("");
    const [loading, setLoading] = useState(true);

    const item = useMemo(() => {
        const itemList = items.filter(v => v[itemId] === selected);
        if (itemList.length) return itemList[0];
        return {};
    }, [selected, items.length]);

    const request = () => {
        dataSourceV2(`${modelName}_list?${itemIdParent}=${itemIdValueParent}`, {url: apiPath})
            .then(v=> {
                if (!v.message && v.item_list && v.item_list.length) {
                    setSelected(values[itemId] || v.item_list[0][itemId]);
                    setItems(v.item_list);
                }
                setLoading(false);
            });
    };

    useEffect(() => {
        if (itemIdValueParent){
            request();
        }

    }, [itemIdValueParent]);

    useEffect(() => {
        console.log("use12");

        if (item[itemId]) {
            console.log("use3");

            setFieldValue(itemId, item[itemId]);
            setFieldValue("delivery_type", item.type);
            setFieldValue("ecommerce_store_delivery_option", item);
            callbackItem(item);

            //setFieldValue("delivery_requested_date", null)
            //setFieldValue("delivery_requested_time", "")
            //setFieldValue("delivery_requested_time_exact", null)
        }
    }, [item[itemId]]);



    return (
            <div className="delivery-step u-flexColumn">

                <div className="u-marginBottom15 u-fontWeightBold u-fontSize16">
                    {readOnly ? "Delivery Method" : "Select Delivery Method"}
                </div>
                <div className=" u-flexCenter u-paddingBottom25 u-borderBottom1 u-borderColorLightest u-xs-flexColumn">
                    {
                        items.map((v, i) => {
                            if ((readOnly || readOnlySelect) && selected !== v[itemId]) {
                                return null;
                            }
                            const selectedClass = selected === v[itemId] ? " selected " : "";
                            return (
                                <div className={"delivery-option u-cursorPointer u-marginRight10 u-xs-marginRight0 u-xs-marginRight0 " + selectedClass} onClick={() => readOnly ? null : setSelected(v[itemId])}>
                                    {v.name || v.type}
                                </div>
                            );
                        })
                    }
                </div>
                <div className="u-paddingVertical15">
                    {
                        loading && <Loading className="u-marginBottom100"/>
                    }
                    {
                        item.type === "pick_up" ?
                            <PickUpForm {...props}
                                        item={item}
                                        description={item.description}
                                        itemIdValueParent={item[itemId]}
                                        itemIdParent={itemId}
                                        modelName={modelName + "_pickup"}
                                        itemId={modelName + "_pickup_id"}/>
                            :
                            (
                                item.type === "delivery" ?
                                    <DeliveryForm {...props} itemIdValue={item[itemId]} item={item}/>
                                    :
                                    item.type === "shipping" ?
                                        <ShippingForm {...props} item={item}
                                                    description={item.description}
                                                    itemIdValueParent={item[itemId]}
                                                    itemIdParent={itemId}
                                                    modelName={modelName + "_rule"}
                                                    itemId={modelName + "_rule_id"}/>
                                        :
                                        item.type === "shipping_event" ?
                                            <ShippingForm {...props} item={item}
                                                        description={item.description}
                                                        itemIdValueParent={item[itemId]}
                                                        itemIdParent={itemId}
                                                        modelName={modelName + "_event"}
                                                        itemId={modelName + "_event_id"}/>
                                            :
                                            item.type === "redirect" ?
                                                <RedirectForm {...props} itemIdValue={item[itemId]} item={item}/>
                                                :
                                                item.type === "shipping_air" || item.type === "air" ?
                                                    <AirForm {...props}
                                                                item={item}
                                                                description={item.description}
                                                                itemIdValueParent={item[itemId]}
                                                                itemIdParent={itemId}
                                                                modelName={modelName + "_rule"}
                                                                itemId={modelName + "_rule_id"}/>
                                                    :
                                                    item.type === "event" ?
                                                        <EventForm {...props}
                                                                    item={item}
                                                                    description={item.description}
                                                                    itemIdValueParent={item[itemId]}
                                                                    itemIdParent={itemId}
                                                                    modelName={modelName + "_event"}
                                                                    itemId={modelName + "_event_id"}/>
                                                                :
                                                                null

                            )
                    }
                    {itemIdValueParent == "26bc06ef-57db-4dfa-80dc-eb4a412c1577" && (
                        <div style={{fontSize:"12px", paddingTop:"20px", color:"#EF4034"}}>
                            We are currently experiencing longer loading times, we apologize for the inconvenience. If there is an issue with your order, please message us on Facebook or Instagram: @charcuterieboardsandsweets</div>)}
                </div>

                {
                    item.type !== "redirect" && !readOnly ?
                        <div className="u-marginBottom100 u-marginTop20">
                            <Button className="button-secondary" size="large" onClick={onNextStep}>
                                Continue
                            </Button>

                            {
                                Object.keys(errors).length || Object.keys(touched).length ?
                                    <div className="MuiFormHelperText-root Mui-error u-marginTop10">See errors above</div>
                                    :
                                    null
                            }
                        </div>
                        :
                        null
                }

            </div>
    );
}
