import React, {useState, useEffect} from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutline from "@material-ui/icons/HelpOutline";
import FormLabel from "@material-ui/core/FormLabel";
import GiftCard from "./GiftCard";
import { getDeliveryUpsaleText } from "../../../../utils";
import {dataSourceV2} from "commons-ui/core/DataSource";
import "./style.styl";


const tipsData = [
    {name: "5%", value: 5},
    {name: "10%", value: 10},
    {name: "15%", value: 15},
    {name: "20%", value: 20},
    {name: "25%", value: 25}
];

export default function Payment(props) {
    const {amount, cart, values, errors, touched, login, onSubmit, onSuccess, setValues, setFieldValue, setFieldError, ecommerceStoreId, apiPath} = props;
    const [tipsCustom, setTipsCustom] = useState(0);
    const deliveryType = values.delivery_type;

    const onDiscountCheck = (externalTransactionId) => {
        const now = new Date();
        const date = values.delivery_type === "event" ? values.event_date_selected : values.delivery_requested_date;
        dataSourceV2(`ecommerce_store_promocode_check?ecommerce_store_id=${ecommerceStoreId}&code=${values.ecommerce_store_promocode_code}&order_placed_at=${now.toISOString()}&delivery_requested_at=${date}`, {url: apiPath})
            .then(v => {
                if (v.message) {
                    setFieldError("ecommerce_store_promocode_code", v.message);
                    setValues({...values, ecommerce_store_promocode: {}, ecommerce_store_promocode_discount_percent: 0, ecommerce_store_promocode_discount: 0});
                    console.log("ERROR");
                } else if (v.item) {
                    setFieldError("ecommerce_store_promocode_code", "");
                    setFieldValue("ecommerce_store_promocode", v.item);
                    if (v.item.discount_percent) {
                        setFieldValue("ecommerce_store_promocode_discount_percent", v.item.discount_percent);
                    } else if (v.item.discount) {
                        setFieldValue("ecommerce_store_promocode_discount", v.item.discount);
                    }
                }
            });
    };

    const onDeleteGiftCard = (number) => {
        const cards = [...values.ecommerce_store_gift_card_list].filter(v => v.number !== number)
        setFieldValue("ecommerce_store_gift_card_list", cards)
    }

    const getEarnPoints = () => {
        const points = values.base_amount * 20
        return Math.floor(points)
    }

    const onDeletePromocode = () => {
      setValues({
        ...values,
        ecommerce_store_promocode_discount_percent: 0,
        ecommerce_store_promocode_discount: 0,
        ecommerce_store_promocode: {},
        ecommerce_store_promocode_code: "",
        promocode: ""
      })
    }


    return (
        <div>
            <RadioGroup className="u-hide" row aria-label="position" name="position" defaultValue="top">
                <FormControlLabel
                  value="top"
                  control={<Radio color="secondary" />}
                  label="Credit Card"
                  labelPlacement="right"
                />
            </RadioGroup>
            {
                !props.isV2 ?
                    <div className="u-sizeFullWidth u-xs-maxWidthNone" style={{maxWidth: "50%"}}>
                        <TextField
                            error={errors.billing_name && touched.billing_name}
                            helperText={errors.billing_name && touched.billing_name ? errors.billing_name : ""}
                            label="Billing Name"
                            margin="dense"
                            value={values.billing_name}
                            className="u-sizeFullWidth u-marginVertical12 u-marginRight20 u-xs-marginRight0"
                            onChange={(e) => setFieldValue("billing_name", e.target.value)}
                            variant="outlined"
                        />

                        <TextField
                            error={errors.billing_address && touched.billing_address}
                            helperText={errors.billing_address && touched.billing_address ? errors.billing_address : ""}
                            label="Billing Address"
                            margin="dense"
                            value={values.billing_address}
                            className="u-sizeFullWidth u-marginVertical12 u-marginRight20 u-xs-marginRight0"
                            onChange={(e) => setFieldValue("billing_address", e.target.value)}
                            variant="outlined"
                        />

                        <TextField
                            error={errors.billing_postal_code && touched.billing_postal_code}
                            helperText={errors.billing_postal_code && touched.billing_postal_code ? errors.billing_postal_code : ""}
                            label="Billing Postal Code"
                            margin="dense"
                            value={values.billing_postal_code}
                            className="u-sizeFullWidth u-marginVertical12 u-marginRight20 u-xs-marginRight0"
                            onChange={(e) => setFieldValue("billing_postal_code", e.target.value)}
                            variant="outlined"
                        />
                    </div>
                    :
                    null
            }
            

            <div className={"u-paddingTop10 u-flex u-alignItemsStart u-sizeFullWidth u-marginVertical12 u-xs-maxWidthNone " + (deliveryType === "shipping_event" ? "u-hide " : "")} style={{maxWidth: "50%"}}>
                <GiftCard {...props} getEarnPoints={getEarnPoints}/>
            </div>

            <div className="u-marginTop30 u-paddingBottom0">
                <span className="u-fontWeightBold u-fontSize18">Summary</span>

                
                <div className="u-marginTop10 u-flexColumn u-sizeFullWidth u-maxWidth300 u-xs-maxWidthNone u-padding15 u-backgroundColorWhite u-borderRadius10 u-border1 u-borderColorLighter">
                    <span className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                        <span>Order:</span>
                        <span>${values.base_amount.toFixed(2)}</span>
                    </span>
                    {
                        values.promocode_amount ?
                            <span className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                                <span>Promocode:</span>
                                <span className="u-flexCenter">
                                  <span onClick={() => onDeletePromocode()} className="link u-marginRight10 u-fontSize8" style={{color: "#4050b5"}}>remove</span>
                                  <span className="u-textColorGreenDark">-${Math.abs(values.promocode_amount || 0).toFixed(2)}</span>
                                </span>
                            </span>
                            :
                            null
                    }
                    {
                        values.labeling_amount ?
                            <span className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                                <span>Labeling:</span>
                                <span>${values.labeling_amount.toFixed(2)}</span>
                            </span>
                            :
                            null
                    }
                    <span className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                        <span>{["event", "pickup", "pick_up"].includes(values.delivery_type) ? "Handling Fee" : (values.delivery_type === "shipping" ? "Shipping Cost" : "Delivery")}:</span>
                        <span>${values.delivery_amount.toFixed(2)}</span>
                    </span>
                    {
                        values.packaging_amount ?
                            <span className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                                <span>Packaging:</span>
                                <span>${values.packaging_amount.toFixed(2)}</span>
                            </span>
                            :
                            null
                    }
                    <span className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                        <span>Tips:</span>
                        <span>${values.tips_amount.toFixed(2)}</span>
                    </span>
                    <span className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                        <span>Tax:</span>
                        <span>${values.tax_amount.toFixed(2)}</span>
                    </span>
                    {
                        values.ecommerce_store_gift_card_list.map((card, i) => {
                            if (!card.amount) {
                                return null
                            }
                            return (
                                <span key={i} className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4">
                                    <span className="u-textColorGreenDark u-textColorGreenNormal">{card.name || ("Gift Card #" + (i + 1).toString())}:</span>
                                    <span className="u-flexCenter">
                                        <span onClick={() => onDeleteGiftCard(card.number)} className="link u-marginRight10 u-fontSize8" style={{color: "#4050b5"}}>remove</span>
                                        <span className="u-fontWeightBold" style={{color: "rgb(27, 95, 6)"}}>-${card.amount.toFixed(2)}</span>
                                    </span>
                                </span>
                            )
                        })
                    }
                    {
                        cart.items.map((item, i) => {
                            console.log(123, item)
                            if (!item) {
                                return null
                            }
                            if (!item.is_free) {
                                return null
                            }

                            return (
                                <span key={i} className="u-flexCenter u-justifyContentSpaceBetween u-marginBottom4 u-fontSize11">
                                    <span style={{color: "rgb(27, 95, 6)"}}>{item.product.name} Free</span>
                                </span>
                            )
                        })
                    }
                    <span className="u-fontWeightBold u-flexCenter u-justifyContentSpaceBetween u-borderTop1 u-borderColorLightest u-paddingTop10">
                        <span>Total:</span>
                        <span>${values.total_amount.toFixed(2)}</span>
                    </span>
                </div>
                {
                    login.token ?
                        <span className="u-flex u-textColorDarkGreen u-marginTop10 u-fontSize12">
                            You will earn <span className="u-fontWeightBold u-paddingHorizontal5" style={{color: "green"}}>{getEarnPoints()}</span> loyalty points from this order
                        </span>
                        :
                        null
                }
                {
                    values.is_frozen ?
                        <span className="u-flex u-marginTop10 u-fontSize12">
                            Your order will be <b className="u-marginLeft5" style={{color: "#6565ec"}}>FROZEN</b>
                        </span>
                        :
                        null
                }

                <span className="u-flex u-fontSize10 u-textColorNormal u-paddingTop10 u-marginLeft18">
                    {getDeliveryUpsaleText((cart.deliveryItem || {}).delivery_cost_json, values.base_amount)}
                </span>
            </div>
            
            <div className="u-marginTop5">
                <FormLabel className="u-flexCenter">Tips{values.tips_amount ? (": $" + values.tips_amount.toFixed(0)) : ""}
                    <Tooltip title="Creating and getting our Piroshky to you is a multilayered process that depends on the talent and contribution of various individuals. When you choose to tip, your gratuity is distributed evenly between all of our crew, from cleaners and prep team, to bakers and drivers. Our team thanks you for your generosity and support.">
                        <HelpOutline className="u-hide u-marginLeft5"/>
                    </Tooltip>

                </FormLabel>
            </div>

            <div className="u-marginTop5 u-flexCenter u-flexWrap">
                {
                    tipsData.map((v, i) => {
                        const isSelected = values.tips_amount_percent === v.value;
                        return (
                            <Chip
                                onClick={() => {
                                    setValues({ ...values, "tips_amount_percent": isSelected ? 0 : v.value, tips_amount_custom: 0 })
                                    setTipsCustom(0)
                                }}
                                label={v.name}
                                className={"tip-button u-marginRight10 u-marginBottom5 u-height40 u-paddingHorizontal10 u-borderRadius50 " + (isSelected ? "selected" : "")}
                              />
                        );
                    })
                }
                <TextField
                    error={errors.tips_amount_custom && touched.tips_amount_custom}
                    margin="dense"
                    type="number"
                    placeholder="$0"
                    helperText={errors.tips_amount_custom && touched.tips_amount_custom ? errors.tips_amount_custom : ""}
                    className="u-sizeFullWidth u-maxWidth80 u-marginBottom12 u-textAlignCenter"
                    onChange={(e) => setTipsCustom(parseFloat(e.target.value) || 0)}
                    onBlur={(e) => !e.target.value || e.target.value.toString().length <= 4 ? setValues({...values, "tips_amount_custom": e.target.value ? (parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : 0) : 0, "tips_amount_percent": 0}) : null}
                    value={parseFloat(tipsCustom ? tipsCustom.toFixed(2) : null)}
                    variant="outlined"
                />
            </div>

            {
                values.ecommerce_store_delivery_option && values.ecommerce_store_delivery_option.tips_description ?
                    <div className="u-marginBottom25">
                        <span className="u-textColorNormal u-fontSize10 u-maxWidth620">
                            {values.ecommerce_store_delivery_option.tips_description}
                        </span>
                    </div>
                    :
                    null
            }

            <div className="u-flex u-alignItemsStart u-sizeFullWidth u-marginVertical12 u-xs-maxWidthNone u-hide" style={{maxWidth: "50%"}}>
                <TextField
                    error={errors.ecommerce_store_promocode_code}
                    label="Promocode"
                    margin="dense"
                    helperText={(errors.ecommerce_store_promocode_code) ? errors.ecommerce_store_promocode_code : (values.promocode_amount ? <span style={{color: "#1b5f06"}}>Promo Code {values.ecommerce_store_promocode_code.toUpperCase()} Applied</span> : "")}
                    className="u-sizeFullWidth u-textAlignCenter"
                    onChange={(e) => setFieldValue("ecommerce_store_promocode_code", e.target.value)}
                    value={values.ecommerce_store_promocode_code}
                    variant="outlined"
                />
            <span>
                <Button className="button-grey u-marginLeft10 u-marginTop6" size="large" onClick={() => onDiscountCheck()}>
                    Apply
                </Button>
            </span>
                
                    

            </div>
        </div>
    );

}
